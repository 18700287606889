export const WORDS: Array<string> = [
    "abbed",
    "abens",
    "abers",
    "abede",
    "abort",
    "acces",
    "adder",
    "adlen",
    "adels",
    "adlet",
    "adler",
    "adles",
    "adled",
    "adlyd",
    "adlød",
    "adræt",
    "advar",
    "advis",
    "aende",
    "aedes",
    "aerob",
    "afart",
    "afasi",
    "afbag",
    "afbud",
    "afbød",
    "afbøj",
    "afdæk",
    "afdød",
    "affat",
    "affed",
    "affej",
    "affyr",
    "affød",
    "affør",
    "afgas",
    "afgiv",
    "afgav",
    "afgud",
    "afgør",
    "afgår",
    "afgik",
    "afhud",
    "afhug",
    "afhør",
    "afise",
    "afkod",
    "afkog",
    "afkom",
    "afkøl",
    "aflad",
    "afled",
    "aflir",
    "afliv",
    "aflok",
    "afluk",
    "aflur",
    "aflus",
    "aflyd",
    "aflys",
    "aflyt",
    "aflæg",
    "aflær",
    "aflæs",
    "afløb",
    "afløn",
    "afløs",
    "afløv",
    "aflås",
    "afmal",
    "afmat",
    "afmej",
    "afmål",
    "afnak",
    "afnød",
    "afpas",
    "afpæl",
    "afret",
    "afrig",
    "afrim",
    "afriv",
    "afrev",
    "afrus",
    "afryd",
    "afrøm",
    "afsav",
    "afset",
    "afses",
    "afsås",
    "afser",
    "afsig",
    "afsky",
    "afslå",
    "afson",
    "afstå",
    "afsyr",
    "afsæb",
    "afsæt",
    "afsat",
    "afsøg",
    "aftag",
    "aftog",
    "aftal",
    "aftap",
    "aften",
    "aftes",
    "aftør",
    "afvej",
    "afvig",
    "afveg",
    "afvis",
    "afvæn",
    "afæsk",
    "agape",
    "agats",
    "agave",
    "agede",
    "agens",
    "agent",
    "agers",
    "agres",
    "agere",
    "agern",
    "agile",
    "agilt",
    "agios",
    "agnen",
    "agnat",
    "agoni",
    "agora",
    "agrar",
    "agtet",
    "agter",
    "agtes",
    "agten",
    "agurk",
    "ahorn",
    "aioli",
    "ajlen",
    "ajles",
    "ajour",
    "aknen",
    "aknes",
    "akryl",
    "akset",
    "aksen",
    "akses",
    "akser",
    "aksel",
    "akten",
    "akter",
    "aktie",
    "aktiv",
    "aktor",
    "aktør",
    "alens",
    "alarm",
    "albas",
    "albue",
    "album",
    "alder",
    "aldre",
    "alene",
    "alert",
    "alfen",
    "alfer",
    "alfas",
    "algen",
    "alges",
    "alger",
    "algod",
    "alhus",
    "alias",
    "alibi",
    "alien",
    "alken",
    "alker",
    "alkes",
    "alkyd",
    "allés",
    "almen",
    "almue",
    "aloen",
    "aloes",
    "aloer",
    "alpen",
    "alper",
    "alpin",
    "alrum",
    "alten",
    "alter",
    "altet",
    "altan",
    "altre",
    "altid",
    "altså",
    "aluns",
    "alvor",
    "ambra",
    "amids",
    "amish",
    "ammen",
    "ammes",
    "ammer",
    "ammet",
    "amorf",
    "ampel",
    "ampul",
    "amtet",
    "amter",
    "amøbe",
    "anale",
    "analt",
    "anden",
    "ænder",
    "andel",
    "andet",
    "andre",
    "anduv",
    "anens",
    "aners",
    "anede",
    "anfør",
    "anger",
    "angiv",
    "angav",
    "angle",
    "angre",
    "angst",
    "angår",
    "angik",
    "anima",
    "anion",
    "anjon",
    "anken",
    "ankes",
    "anker",
    "anket",
    "ankel",
    "ankre",
    "ankom",
    "anlæg",
    "anløb",
    "anmas",
    "anmod",
    "annal",
    "annam",
    "annua",
    "anode",
    "anret",
    "anråb",
    "ansat",
    "anset",
    "anses",
    "ansås",
    "anser",
    "ansku",
    "anslå",
    "anstå",
    "ansæt",
    "ansøg",
    "antag",
    "antog",
    "antal",
    "antik",
    "antyd",
    "anvis",
    "anæmi",
    "aorta",
    "apati",
    "apnøs",
    "appen",
    "apper",
    "appel",
    "april",
    "apsis",
    "apter",
    "arens",
    "arene",
    "arret",
    "araen",
    "araer",
    "arden",
    "arder",
    "areal",
    "arena",
    "argon",
    "argot",
    "arien",
    "aries",
    "arier",
    "arisk",
    "arken",
    "arker",
    "arket",
    "arkiv",
    "armen",
    "armes",
    "armés",
    "armer",
    "armod",
    "arnen",
    "arnes",
    "arner",
    "aroma",
    "arpen",
    "arrak",
    "arrer",
    "arres",
    "arrig",
    "arsen",
    "arsis",
    "arses",
    "arten",
    "arter",
    "artet",
    "artes",
    "artig",
    "arven",
    "arves",
    "arver",
    "arvet",
    "arvæv",
    "asens",
    "asers",
    "asset",
    "asser",
    "asede",
    "asnet",
    "asner",
    "asiat",
    "asien",
    "asies",
    "asier",
    "asiet",
    "asken",
    "askes",
    "asker",
    "asket",
    "aspen",
    "aspes",
    "aspic",
    "astma",
    "asyls",
    "atlas",
    "atlet",
    "atols",
    "atoms",
    "atoni",
    "attak",
    "atten",
    "atter",
    "attrå",
    "audio",
    "audit",
    "augur",
    "aulas",
    "auras",
    "autor",
    "avene",
    "avede",
    "avecs",
    "avers",
    "avind",
    "avlen",
    "avlet",
    "avler",
    "avles",
    "avnen",
    "avnes",
    "avner",
    "azurs",
    "babys",
    "backs",
    "bacon",
    "badet",
    "bades",
    "bader",
    "badge",
    "badut",
    "bagen",
    "bages",
    "bagbo",
    "baget",
    "bager",
    "bagte",
    "bagel",
    "bag i",
    "bagom",
    "bagpå",
    "bagud",
    "bajer",
    "bakke",
    "bakse",
    "balde",
    "baldr",
    "balje",
    "balle",
    "balsa",
    "bamle",
    "bamse",
    "banal",
    "banan",
    "bands",
    "bande",
    "bandy",
    "banen",
    "banes",
    "baner",
    "banet",
    "bange",
    "banje",
    "banjo",
    "banks",
    "banke",
    "banko",
    "bantu",
    "bappe",
    "baren",
    "barer",
    "barak",
    "barbe",
    "barde",
    "baret",
    "bares",
    "barks",
    "barms",
    "barme",
    "barns",
    "børns",
    "barok",
    "baron",
    "barre",
    "barsk",
    "barsl",
    "baryl",
    "baryt",
    "basal",
    "basar",
    "basen",
    "bases",
    "baser",
    "basic",
    "basis",
    "basks",
    "baske",
    "basse",
    "basts",
    "basta",
    "baste",
    "basun",
    "batat",
    "batch",
    "batik",
    "batte",
    "batts",
    "bauds",
    "bavls",
    "bavle",
    "bavns",
    "bavne",
    "beats",
    "bebos",
    "bebor",
    "bebop",
    "bebud",
    "bebyg",
    "bedet",
    "bedes",
    "beden",
    "beder",
    "bedre",
    "bedyr",
    "bedæk",
    "bedøm",
    "bedøv",
    "bedår",
    "befal",
    "befar",
    "befor",
    "befat",
    "befri",
    "beføl",
    "beget",
    "begav",
    "begge",
    "begit",
    "begiv",
    "beglo",
    "begær",
    "begås",
    "begår",
    "begik",
    "behag",
    "behov",
    "behøv",
    "beige",
    "bejae",
    "bejds",
    "bejle",
    "bekom",
    "belav",
    "belur",
    "belys",
    "belæg",
    "belær",
    "belæs",
    "beløb",
    "beløn",
    "belån",
    "bemal",
    "bemøg",
    "bemøj",
    "benet",
    "bener",
    "benes",
    "benyt",
    "benåd",
    "beoen",
    "beoer",
    "beram",
    "berap",
    "bered",
    "beret",
    "berig",
    "beror",
    "berus",
    "beryl",
    "berøm",
    "berør",
    "berøv",
    "beråb",
    "beset",
    "beses",
    "besås",
    "beser",
    "besid",
    "besad",
    "beskt",
    "beske",
    "besku",
    "beslå",
    "bestå",
    "besyv",
    "besæt",
    "besat",
    "besøg",
    "besår",
    "beten",
    "beter",
    "betas",
    "betag",
    "betog",
    "betak",
    "betal",
    "betel",
    "betle",
    "beton",
    "betro",
    "betyd",
    "betød",
    "bevar",
    "bevis",
    "bevæg",
    "beære",
    "biens",
    "biers",
    "biets",
    "biavl",
    "bibel",
    "bider",
    "bides",
    "bidet",
    "bidsk",
    "biede",
    "bifag",
    "bifil",
    "bigot",
    "bijob",
    "biker",
    "bikse",
    "bilen",
    "biler",
    "bilag",
    "bilde",
    "bildt",
    "bilet",
    "biles",
    "biled",
    "bille",
    "bilos",
    "bilyd",
    "bilæg",
    "bimbo",
    "bimle",
    "bimse",
    "binds",
    "binde",
    "bandt",
    "bingo",
    "binær",
    "bioen",
    "bioer",
    "biord",
    "bippe",
    "birks",
    "birke",
    "bisam",
    "bisen",
    "bises",
    "biser",
    "bisol",
    "bison",
    "bisps",
    "bisse",
    "bistå",
    "bisæt",
    "bisat",
    "bitch",
    "bitte",
    "bitre",
    "bivej",
    "bivån",
    "bizar",
    "bjerg",
    "bjesk",
    "bjæfs",
    "bjærg",
    "bjørn",
    "blads",
    "blade",
    "bladr",
    "blafs",
    "blafr",
    "bland",
    "blank",
    "blase",
    "bleen",
    "bleer",
    "blege",
    "blegt",
    "blegn",
    "blend",
    "blide",
    "blidt",
    "bliks",
    "blind",
    "blini",
    "blink",
    "blist",
    "blitz",
    "blive",
    "blods",
    "blogs",
    "bloks",
    "blond",
    "blots",
    "blote",
    "blues",
    "blufs",
    "bluff",
    "blund",
    "bluse",
    "blyet",
    "blæks",
    "blænd",
    "blærs",
    "blære",
    "blæse",
    "blæst",
    "blævr",
    "bløde",
    "blødt",
    "blåne",
    "blårs",
    "boets",
    "boers",
    "boede",
    "boaen",
    "boaer",
    "board",
    "bobbe",
    "boble",
    "boden",
    "boder",
    "boere",
    "bogen",
    "bøger",
    "boget",
    "boger",
    "boges",
    "bogey",
    "bogie",
    "bojan",
    "bokse",
    "bolds",
    "bolde",
    "boldt",
    "bolet",
    "boler",
    "boles",
    "bolig",
    "bolle",
    "bolts",
    "bolte",
    "boltr",
    "bomme",
    "bombe",
    "bonen",
    "boner",
    "bonde",
    "bonet",
    "bones",
    "bonus",
    "booke",
    "booms",
    "boome",
    "boost",
    "boote",
    "bopæl",
    "boret",
    "bords",
    "borde",
    "borer",
    "bores",
    "borgs",
    "borge",
    "borne",
    "borst",
    "borts",
    "borte",
    "bosat",
    "bosse",
    "bosæt",
    "boule",
    "boven",
    "boves",
    "bowle",
    "boxer",
    "brags",
    "brage",
    "braks",
    "brakt",
    "bralr",
    "brand",
    "brank",
    "brase",
    "brast",
    "brask",
    "brave",
    "bravt",
    "bravo",
    "break",
    "breds",
    "brede",
    "bredt",
    "brems",
    "brevs",
    "breve",
    "brien",
    "bries",
    "brier",
    "brief",
    "brigs",
    "briks",
    "bring",
    "bragt",
    "brink",
    "brint",
    "brise",
    "brist",
    "brite",
    "broen",
    "broer",
    "brods",
    "broks",
    "broms",
    "brors",
    "brovt",
    "brows",
    "bruds",
    "brude",
    "brugs",
    "bruge",
    "brugt",
    "brums",
    "brune",
    "brunt",
    "bruse",
    "brust",
    "brusk",
    "bryde",
    "brudt",
    "brygs",
    "bryns",
    "brysk",
    "bryst",
    "bræen",
    "bræer",
    "bræge",
    "bræks",
    "brænd",
    "bræts",
    "brøds",
    "brøde",
    "brøks",
    "brøls",
    "brøle",
    "brønd",
    "brøst",
    "budes",
    "buens",
    "buers",
    "buede",
    "bugen",
    "buges",
    "buggy",
    "bugne",
    "bugts",
    "bugte",
    "buhet",
    "buher",
    "buhes",
    "buhko",
    "bukke",
    "buket",
    "bukle",
    "bulle",
    "buldr",
    "bulen",
    "bules",
    "buler",
    "bulet",
    "bulks",
    "bulke",
    "bulne",
    "bumle",
    "bumps",
    "bumpe",
    "bumse",
    "bunds",
    "bunde",
    "bundt",
    "bunke",
    "bunkr",
    "buret",
    "bures",
    "burde",
    "burer",
    "burka",
    "burre",
    "buset",
    "buser",
    "buses",
    "bushs",
    "busks",
    "buske",
    "buste",
    "butte",
    "butan",
    "butik",
    "byens",
    "byers",
    "bybos",
    "bybud",
    "bybus",
    "byder",
    "bydes",
    "bydel",
    "bygas",
    "bygds",
    "bygen",
    "byges",
    "byger",
    "byget",
    "bygge",
    "bylds",
    "byliv",
    "bylts",
    "bylte",
    "bynke",
    "bynær",
    "byrds",
    "byrde",
    "byret",
    "byrum",
    "byråd",
    "byste",
    "byten",
    "bytes",
    "bytte",
    "bytur",
    "bæens",
    "bæers",
    "bæets",
    "bæger",
    "bægre",
    "bække",
    "bælgs",
    "bælge",
    "bælle",
    "bælts",
    "bælte",
    "bænks",
    "bænke",
    "bårne",
    "båren",
    "båret",
    "bærer",
    "bæres",
    "bærme",
    "bæsts",
    "bævet",
    "bæver",
    "bæves",
    "bæven",
    "bævls",
    "bævre",
    "bøden",
    "bødes",
    "bøder",
    "bødet",
    "bøffe",
    "bøgen",
    "bøges",
    "bøget",
    "bøjen",
    "bøjes",
    "bøjer",
    "bøjet",
    "bøjle",
    "bølge",
    "bølle",
    "bønne",
    "børen",
    "børes",
    "børst",
    "bøsse",
    "bøtte",
    "bøvet",
    "bøvls",
    "bøvle",
    "bøvse",
    "båden",
    "bådes",
    "båken",
    "båkes",
    "båker",
    "bålet",
    "bånds",
    "bånde",
    "båres",
    "bårer",
    "båsen",
    "båses",
    "båset",
    "båser",
    "båtte",
    "cafés",
    "cajun",
    "camps",
    "capen",
    "capes",
    "caper",
    "casen",
    "cases",
    "caste",
    "cater",
    "cavas",
    "ceder",
    "cedre",
    "celle",
    "cello",
    "cents",
    "centr",
    "cerut",
    "chais",
    "charm",
    "chats",
    "check",
    "chefs",
    "chiet",
    "chier",
    "chikt",
    "chili",
    "chips",
    "choks",
    "cider",
    "cifre",
    "cigar",
    "cirka",
    "cirkl",
    "citar",
    "citat",
    "citer",
    "citre",
    "citys",
    "civil",
    "clear",
    "clips",
    "clous",
    "coach",
    "coate",
    "codas",
    "colas",
    "conga",
    "coole",
    "coolt",
    "corny",
    "coupé",
    "cover",
    "crack",
    "crawl",
    "crazy",
    "credo",
    "creme",
    "crepe",
    "croon",
    "cross",
    "curie",
    "cutte",
    "cyans",
    "cykel",
    "cykle",
    "cyste",
    "cæsar",
    "cæsur",
    "dadas",
    "dadel",
    "dadle",
    "dagen",
    "dages",
    "dalen",
    "dales",
    "dalbo",
    "dalet",
    "daler",
    "dalre",
    "damme",
    "damen",
    "dames",
    "damer",
    "damet",
    "damps",
    "dampe",
    "danbo",
    "dandy",
    "daner",
    "danne",
    "danse",
    "dansk",
    "darts",
    "daset",
    "daser",
    "dases",
    "dasks",
    "daske",
    "datas",
    "daten",
    "dates",
    "datet",
    "dater",
    "datid",
    "dativ",
    "datja",
    "datos",
    "døtre",
    "david",
    "davre",
    "deres",
    "deals",
    "deale",
    "debat",
    "debet",
    "debil",
    "debut",
    "decim",
    "degns",
    "degne",
    "deist",
    "dejen",
    "dejes",
    "dejse",
    "dekan",
    "dekor",
    "delen",
    "deles",
    "deler",
    "delte",
    "delle",
    "delta",
    "demos",
    "denar",
    "denim",
    "denne",
    "dette",
    "disse",
    "dente",
    "deoen",
    "deoer",
    "depot",
    "deraf",
    "derby",
    "derom",
    "derop",
    "derpå",
    "derud",
    "desto",
    "devon",
    "devot",
    "diede",
    "diget",
    "diges",
    "diger",
    "digel",
    "digre",
    "digts",
    "digte",
    "dikke",
    "dikta",
    "dilds",
    "dildo",
    "dille",
    "dinar",
    "diner",
    "dingl",
    "dingo",
    "diode",
    "dipol",
    "dirks",
    "dirke",
    "dirre",
    "disen",
    "diset",
    "disks",
    "diske",
    "disko",
    "ditto",
    "divas",
    "divan",
    "diæts",
    "djærv",
    "doets",
    "doers",
    "doble",
    "docer",
    "dogen",
    "doges",
    "doger",
    "dogme",
    "dokke",
    "dolce",
    "dolks",
    "dolke",
    "dolme",
    "domme",
    "domen",
    "domer",
    "donen",
    "dones",
    "doner",
    "donna",
    "donor",
    "donts",
    "donut",
    "dopen",
    "dopes",
    "dopet",
    "doper",
    "dorer",
    "dorgs",
    "dorge",
    "dorns",
    "dorne",
    "dorsk",
    "doser",
    "dosis",
    "dosen",
    "douce",
    "douch",
    "doula",
    "doven",
    "dovne",
    "doyen",
    "dozer",
    "drabs",
    "drags",
    "drage",
    "dragé",
    "dragt",
    "drams",
    "drama",
    "drapa",
    "drejs",
    "dreje",
    "drejl",
    "dreng",
    "dress",
    "drevs",
    "dribl",
    "drift",
    "driks",
    "drils",
    "drink",
    "drist",
    "drive",
    "drogs",
    "droge",
    "drone",
    "drops",
    "drosl",
    "drots",
    "drude",
    "druen",
    "drues",
    "druer",
    "drugs",
    "druks",
    "drukn",
    "dryps",
    "dræet",
    "dræbe",
    "dræbt",
    "drægs",
    "dræns",
    "dræne",
    "dræve",
    "drøft",
    "drøje",
    "drøjt",
    "drøms",
    "drømt",
    "drøns",
    "drøne",
    "drøvl",
    "dråbe",
    "duede",
    "dubbe",
    "duble",
    "dublé",
    "duens",
    "duers",
    "duels",
    "duets",
    "dufts",
    "dufte",
    "dugen",
    "duges",
    "dugge",
    "dugts",
    "dukat",
    "dukke",
    "dukse",
    "dulle",
    "dulme",
    "dumme",
    "dummy",
    "dumps",
    "dumpe",
    "dumpt",
    "dunet",
    "dundr",
    "dunks",
    "dunke",
    "dunst",
    "duoen",
    "duoer",
    "duper",
    "duppe",
    "duret",
    "durer",
    "dures",
    "durum",
    "dusin",
    "dusks",
    "duske",
    "dusør",
    "dutte",
    "duvet",
    "duver",
    "duves",
    "dvale",
    "dvask",
    "dvæle",
    "dværg",
    "dyede",
    "dybet",
    "dybde",
    "dybsø",
    "dyden",
    "dyder",
    "dydig",
    "dykke",
    "dynds",
    "dynen",
    "dynes",
    "dyner",
    "dynge",
    "dyppe",
    "dyret",
    "dyrke",
    "dysen",
    "dyses",
    "dyser",
    "dysse",
    "dysts",
    "dyste",
    "dytte",
    "dyvel",
    "dægge",
    "dække",
    "dælen",
    "dæmme",
    "dæmon",
    "dæmpe",
    "dæmre",
    "dænge",
    "dødes",
    "døber",
    "døbte",
    "døbes",
    "døden",
    "dødet",
    "døder",
    "dødis",
    "døgns",
    "døjes",
    "døjet",
    "døjer",
    "dølge",
    "dulgt",
    "dølle",
    "dømme",
    "dømte",
    "dønne",
    "døren",
    "døres",
    "dørgs",
    "dørks",
    "dørke",
    "døsen",
    "døset",
    "døser",
    "døses",
    "døsig",
    "døvet",
    "døver",
    "døves",
    "dåens",
    "dåers",
    "dåben",
    "dåden",
    "dådyr",
    "dålam",
    "dånet",
    "dåner",
    "dånes",
    "dåren",
    "dåres",
    "dårer",
    "dåret",
    "dåsen",
    "dåses",
    "dåser",
    "eagle",
    "ebben",
    "ebbes",
    "ebbet",
    "ebber",
    "ebola",
    "eclat",
    "edens",
    "eders",
    "edder",
    "edikt",
    "efeus",
    "effen",
    "efors",
    "efter",
    "egens",
    "egene",
    "egale",
    "egalt",
    "egers",
    "egent",
    "egern",
    "egnen",
    "egnes",
    "egnet",
    "egner",
    "egoet",
    "egoer",
    "eiden",
    "ejede",
    "ejers",
    "ejere",
    "ekkos",
    "ekkoe",
    "eksen",
    "ekser",
    "ekset",
    "ekses",
    "eksem",
    "eksil",
    "ellen",
    "elles",
    "elans",
    "elbil",
    "elbos",
    "elegi",
    "elevs",
    "elgen",
    "elges",
    "elgko",
    "elite",
    "eller",
    "elmen",
    "elmes",
    "elske",
    "elven",
    "elves",
    "elver",
    "emmen",
    "emden",
    "emdes",
    "emder",
    "emirs",
    "emmet",
    "emmer",
    "emmes",
    "emnet",
    "emnes",
    "emner",
    "emoji",
    "emsig",
    "emuen",
    "emuer",
    "endda",
    "enden",
    "endes",
    "ender",
    "endte",
    "endnu",
    "endog",
    "enens",
    "eners",
    "enere",
    "engen",
    "enges",
    "engel",
    "engle",
    "enhed",
    "enige",
    "enigt",
    "enken",
    "enkes",
    "enker",
    "enkel",
    "enkle",
    "enlig",
    "enorm",
    "enrum",
    "ensom",
    "ental",
    "entes",
    "enten",
    "entre",
    "entré",
    "enzym",
    "enøre",
    "eolit",
    "epiks",
    "episk",
    "epoen",
    "epoet",
    "epoke",
    "epopé",
    "epoxy",
    "erfar",
    "erika",
    "erlæg",
    "ernær",
    "erobr",
    "erots",
    "esset",
    "esser",
    "espen",
    "espes",
    "esrom",
    "essay",
    "essen",
    "esses",
    "essig",
    "ester",
    "etaet",
    "etaer",
    "etage",
    "etape",
    "etats",
    "etbær",
    "etiks",
    "etisk",
    "etmål",
    "ettal",
    "etter",
    "etude",
    "etuis",
    "etårs",
    "eunuk",
    "euros",
    "event",
    "evert",
    "evige",
    "evigt",
    "evnen",
    "evnes",
    "evner",
    "evnet",
    "exces",
    "exede",
    "exits",
    "expat",
    "faets",
    "faers",
    "fabel",
    "fable",
    "facet",
    "facil",
    "facit",
    "facon",
    "facts",
    "fadet",
    "fades",
    "fader",
    "fædre",
    "fadøl",
    "faget",
    "fager",
    "fagre",
    "fagot",
    "fakes",
    "faket",
    "faker",
    "fakir",
    "fakta",
    "falds",
    "falde",
    "faldt",
    "falks",
    "falke",
    "falle",
    "falme",
    "false",
    "falsk",
    "famle",
    "famøs",
    "fancy",
    "fanen",
    "fanes",
    "faner",
    "fange",
    "faren",
    "farad",
    "farao",
    "farce",
    "fares",
    "farer",
    "faret",
    "farne",
    "farin",
    "farms",
    "farme",
    "farsi",
    "farts",
    "farte",
    "farve",
    "fasen",
    "faser",
    "fases",
    "fasan",
    "faset",
    "faste",
    "fatal",
    "fatte",
    "fatwa",
    "fauns",
    "fauna",
    "favns",
    "favne",
    "favør",
    "faxen",
    "faxer",
    "faxet",
    "faxes",
    "feens",
    "feers",
    "feber",
    "febre",
    "fedet",
    "feder",
    "fedes",
    "fedme",
    "fedts",
    "fedte",
    "feeds",
    "fehår",
    "fejde",
    "fejet",
    "fejer",
    "fejes",
    "fejls",
    "fejle",
    "fejre",
    "felts",
    "femte",
    "femti",
    "femår",
    "fenne",
    "fenol",
    "fenyl",
    "feren",
    "feres",
    "ferie",
    "ferle",
    "ferme",
    "fermt",
    "fersk",
    "fests",
    "feste",
    "fetas",
    "feter",
    "fezen",
    "fezer",
    "fiets",
    "fiers",
    "fiber",
    "fibre",
    "ficus",
    "fidel",
    "fidus",
    "fifle",
    "figen",
    "fight",
    "figur",
    "fikse",
    "fikst",
    "filen",
    "files",
    "filer",
    "filet",
    "films",
    "filme",
    "filts",
    "filte",
    "filtr",
    "filur",
    "fimet",
    "fimre",
    "fimse",
    "final",
    "finde",
    "fandt",
    "finer",
    "fingr",
    "finit",
    "finke",
    "finne",
    "finsk",
    "finte",
    "fiols",
    "fires",
    "firet",
    "firer",
    "firma",
    "firme",
    "firti",
    "fisen",
    "fises",
    "feset",
    "fiset",
    "fiser",
    "fiste",
    "fisks",
    "fiske",
    "fisse",
    "fistr",
    "fixet",
    "fixer",
    "fixes",
    "fjant",
    "fjase",
    "fjeds",
    "fjedr",
    "fjeld",
    "fjers",
    "fjern",
    "fjert",
    "fjogs",
    "fjolr",
    "fjols",
    "fjong",
    "fjord",
    "fjumr",
    "fjums",
    "fjæls",
    "fjæle",
    "fjært",
    "flabs",
    "flabe",
    "flade",
    "fladt",
    "flags",
    "flage",
    "flagr",
    "flair",
    "flaks",
    "flakt",
    "flane",
    "flaps",
    "flapr",
    "flash",
    "flask",
    "fleks",
    "flere",
    "flest",
    "flets",
    "flids",
    "fligs",
    "flige",
    "fliks",
    "flimr",
    "flink",
    "flint",
    "flips",
    "flire",
    "flirt",
    "flise",
    "flods",
    "floks",
    "floms",
    "flops",
    "flors",
    "flora",
    "flove",
    "flovt",
    "flows",
    "fluen",
    "flues",
    "fluer",
    "flugt",
    "fluid",
    "fluks",
    "fluns",
    "fluor",
    "flute",
    "flyet",
    "flyes",
    "flyer",
    "flyde",
    "flydt",
    "flygt",
    "flyve",
    "flæbs",
    "flæbe",
    "flægs",
    "flæng",
    "flæns",
    "flæse",
    "flæsk",
    "fløde",
    "fløjs",
    "fløje",
    "fløjl",
    "fløjt",
    "fløse",
    "flået",
    "flåds",
    "flåde",
    "flåts",
    "fnats",
    "fnise",
    "fnist",
    "fnoks",
    "fnugs",
    "fnulr",
    "fnyse",
    "fnyst",
    "fobis",
    "foden",
    "foder",
    "fodre",
    "foget",
    "foged",
    "fokke",
    "fokus",
    "folds",
    "folde",
    "folen",
    "foles",
    "foler",
    "folet",
    "folie",
    "folio",
    "folks",
    "fonen",
    "foner",
    "fonds",
    "fonde",
    "fonem",
    "fonts",
    "fonte",
    "foret",
    "foran",
    "forbi",
    "force",
    "fordi",
    "fordr",
    "forer",
    "fores",
    "forel",
    "foren",
    "forgå",
    "forks",
    "forke",
    "forku",
    "forms",
    "forme",
    "formå",
    "forne",
    "forny",
    "forpå",
    "forrå",
    "forse",
    "forså",
    "forsk",
    "forts",
    "forte",
    "forti",
    "forud",
    "forum",
    "foras",
    "foræd",
    "foråd",
    "forær",
    "forød",
    "forøg",
    "forøv",
    "forår",
    "fosse",
    "fostr",
    "fotos",
    "foton",
    "foyer",
    "fragt",
    "fragå",
    "franc",
    "frank",
    "frase",
    "freak",
    "freds",
    "frede",
    "frels",
    "freon",
    "friet",
    "fries",
    "frier",
    "frise",
    "frisé",
    "frisk",
    "frist",
    "fromt",
    "front",
    "frost",
    "fruen",
    "frues",
    "fruer",
    "frugt",
    "fryds",
    "fryde",
    "frygt",
    "fryns",
    "fryse",
    "frækt",
    "fræse",
    "frøen",
    "frøer",
    "frøet",
    "fråde",
    "fråse",
    "fucke",
    "fugas",
    "fugen",
    "fuges",
    "fuger",
    "fuget",
    "fugls",
    "fugle",
    "fugts",
    "fugte",
    "fukse",
    "fulde",
    "fuldt",
    "fumle",
    "funds",
    "funks",
    "funke",
    "funkl",
    "funky",
    "fuppe",
    "furet",
    "furen",
    "fures",
    "furer",
    "furie",
    "fuset",
    "fuser",
    "fuses",
    "fusel",
    "fusen",
    "fusks",
    "fuske",
    "futil",
    "futon",
    "futte",
    "fyget",
    "føget",
    "fyger",
    "fyges",
    "fylds",
    "fylde",
    "fyldt",
    "fylke",
    "fynbo",
    "fynds",
    "fynsk",
    "fyord",
    "fyren",
    "fyres",
    "fyret",
    "fyrre",
    "fyrer",
    "fyrig",
    "fyråb",
    "fysik",
    "fytin",
    "fæets",
    "fæene",
    "fæces",
    "fægte",
    "fæhår",
    "fæisk",
    "fækal",
    "fælde",
    "fælgs",
    "fælge",
    "fælle",
    "fænge",
    "færds",
    "færge",
    "færts",
    "fæste",
    "fæstn",
    "fætre",
    "føden",
    "fødes",
    "fødet",
    "føder",
    "fødte",
    "føjes",
    "føjet",
    "føjer",
    "føjte",
    "føler",
    "følte",
    "føles",
    "følge",
    "fulgt",
    "fønen",
    "føner",
    "fønet",
    "fønes",
    "føret",
    "føres",
    "fører",
    "førte",
    "førns",
    "førne",
    "først",
    "færre",
    "fåret",
    "fåtal",
    "gabet",
    "gaber",
    "gabte",
    "gabes",
    "gaden",
    "gades",
    "gader",
    "gaffe",
    "gafle",
    "gagat",
    "gagen",
    "gages",
    "gager",
    "galet",
    "galan",
    "galar",
    "galde",
    "galer",
    "gales",
    "galej",
    "galge",
    "galla",
    "galle",
    "galon",
    "galop",
    "galpe",
    "galts",
    "galte",
    "gambe",
    "gambl",
    "gamet",
    "games",
    "gamer",
    "gamma",
    "gamle",
    "ældre",
    "ældst",
    "ganen",
    "ganes",
    "ganer",
    "gangs",
    "gange",
    "garde",
    "garns",
    "garve",
    "gasen",
    "gases",
    "gaser",
    "gasse",
    "gasts",
    "gaten",
    "gates",
    "gauge",
    "gauss",
    "gaven",
    "gaves",
    "gaver",
    "gavls",
    "gavle",
    "gavns",
    "gavne",
    "gazen",
    "gazes",
    "gazer",
    "gears",
    "geare",
    "gebet",
    "gebis",
    "gebyr",
    "geden",
    "geder",
    "gedde",
    "gehør",
    "gejls",
    "gejle",
    "gejlt",
    "gejst",
    "gekko",
    "gelen",
    "geler",
    "gelés",
    "geled",
    "gemak",
    "gemal",
    "gemen",
    "gemme",
    "gemte",
    "gemse",
    "gemyt",
    "genet",
    "gener",
    "genbo",
    "genen",
    "genes",
    "genis",
    "genne",
    "genom",
    "genre",
    "gense",
    "genså",
    "genua",
    "genus",
    "geret",
    "gerer",
    "geres",
    "gerne",
    "geråd",
    "gests",
    "gevir",
    "gevær",
    "giens",
    "giers",
    "giede",
    "gibbe",
    "gidet",
    "gider",
    "gides",
    "gifts",
    "gifte",
    "gigen",
    "giges",
    "giger",
    "gigts",
    "gigue",
    "gilde",
    "gimpe",
    "ginen",
    "gines",
    "giner",
    "gipse",
    "giraf",
    "giret",
    "girer",
    "gires",
    "giros",
    "gisne",
    "gisps",
    "gispe",
    "gitre",
    "givne",
    "given",
    "givet",
    "giver",
    "gives",
    "gjald",
    "gjord",
    "glade",
    "glams",
    "glane",
    "glans",
    "glemt",
    "glibs",
    "glibe",
    "glids",
    "glide",
    "glimr",
    "glimt",
    "glins",
    "gliom",
    "glitr",
    "gloet",
    "globe",
    "glose",
    "glugs",
    "gluts",
    "glyfs",
    "glæde",
    "gløds",
    "gløde",
    "gløgg",
    "gnask",
    "gnavs",
    "gnave",
    "gnejs",
    "gnide",
    "gnidr",
    "gnier",
    "gnist",
    "gnoms",
    "gnome",
    "gnuen",
    "gnuer",
    "gnyet",
    "bedst",
    "goden",
    "godes",
    "goder",
    "godet",
    "godte",
    "gogos",
    "gokke",
    "golde",
    "goldt",
    "golfs",
    "gomme",
    "gongs",
    "gonge",
    "googl",
    "gople",
    "goter",
    "goths",
    "gotik",
    "gouda",
    "grabs",
    "grads",
    "grade",
    "grafs",
    "grals",
    "grams",
    "gramt",
    "grans",
    "grand",
    "grant",
    "grape",
    "grats",
    "gravs",
    "grave",
    "grays",
    "grebs",
    "grebe",
    "green",
    "grejs",
    "greje",
    "grelt",
    "grens",
    "grene",
    "greve",
    "gribs",
    "gribe",
    "grifs",
    "grifl",
    "grill",
    "grimt",
    "grime",
    "grins",
    "grind",
    "grine",
    "grint",
    "grips",
    "grise",
    "grisk",
    "groet",
    "grogs",
    "groom",
    "grove",
    "groft",
    "growl",
    "gruen",
    "grube",
    "grubl",
    "gruet",
    "gruer",
    "grues",
    "gruks",
    "grumt",
    "grums",
    "grund",
    "gruse",
    "gruts",
    "gryet",
    "gryer",
    "gryde",
    "gryns",
    "grynt",
    "græde",
    "grædt",
    "græns",
    "græsk",
    "grøbl",
    "grøds",
    "grøde",
    "grøft",
    "grønt",
    "gråds",
    "gråne",
    "guano",
    "guava",
    "gubbe",
    "guden",
    "guder",
    "guffe",
    "gufle",
    "guide",
    "gulds",
    "gulne",
    "gulvs",
    "gulve",
    "gumle",
    "gumme",
    "gummi",
    "gumps",
    "gumpe",
    "gungr",
    "gunst",
    "guppy",
    "gurgl",
    "gurus",
    "gusen",
    "gyden",
    "gydes",
    "gyder",
    "gyldn",
    "gylle",
    "gylps",
    "gylpe",
    "gylts",
    "gylte",
    "gynge",
    "gyros",
    "gyset",
    "gyser",
    "gyste",
    "gyses",
    "gytje",
    "gyvel",
    "gække",
    "gælds",
    "gælde",
    "gældt",
    "gæler",
    "gælle",
    "gængs",
    "gænge",
    "gæren",
    "gærde",
    "gæret",
    "gærer",
    "gæres",
    "gæsts",
    "gæste",
    "gætte",
    "gøede",
    "gødet",
    "gøder",
    "gødes",
    "gødsk",
    "gøens",
    "gøgen",
    "gøges",
    "gøgls",
    "gøgle",
    "gønge",
    "gjort",
    "gøres",
    "gøsen",
    "gøses",
    "gåben",
    "gåden",
    "gådes",
    "gåder",
    "gålæg",
    "gårds",
    "gårde",
    "gåsen",
    "gåset",
    "gåtid",
    "gåtur",
    "habil",
    "habit",
    "hacke",
    "hadet",
    "hader",
    "hades",
    "hadsk",
    "hagen",
    "hages",
    "hager",
    "haget",
    "hagls",
    "hagle",
    "haiku",
    "hajen",
    "hajer",
    "hakke",
    "halal",
    "halen",
    "hales",
    "haler",
    "halet",
    "halls",
    "hallo",
    "halms",
    "halma",
    "halos",
    "halon",
    "halse",
    "halte",
    "halve",
    "halvt",
    "halvø",
    "hamme",
    "hamam",
    "hamit",
    "hamle",
    "hamre",
    "hamps",
    "handl",
    "handy",
    "hanen",
    "hanes",
    "haner",
    "hangs",
    "hanks",
    "hanke",
    "hapse",
    "haram",
    "haren",
    "hares",
    "harer",
    "harem",
    "harks",
    "harke",
    "harms",
    "harme",
    "harmt",
    "harpe",
    "harpy",
    "harsk",
    "harve",
    "hasen",
    "haser",
    "hases",
    "hashs",
    "haspe",
    "hasts",
    "haste",
    "hatte",
    "hausa",
    "havet",
    "haves",
    "haven",
    "haver",
    "havde",
    "havns",
    "havne",
    "havre",
    "havål",
    "heade",
    "heale",
    "heats",
    "heavy",
    "hedde",
    "heden",
    "hedes",
    "heder",
    "hegle",
    "hegns",
    "hegne",
    "heile",
    "hejre",
    "hejsa",
    "hejse",
    "hejst",
    "hekse",
    "helds",
    "heles",
    "helet",
    "heler",
    "helle",
    "helme",
    "helse",
    "helst",
    "helts",
    "helte",
    "helår",
    "heman",
    "hemen",
    "hemse",
    "henad",
    "hendø",
    "hengå",
    "henlå",
    "henna",
    "henne",
    "henry",
    "hense",
    "henså",
    "hente",
    "heppe",
    "heraf",
    "herme",
    "heros",
    "herom",
    "herop",
    "herpå",
    "herre",
    "herse",
    "hersk",
    "hertz",
    "herud",
    "herut",
    "hests",
    "heste",
    "hetze",
    "hiets",
    "hiers",
    "hiats",
    "hidse",
    "higet",
    "higer",
    "higes",
    "hijab",
    "hiken",
    "hikes",
    "hiker",
    "hiket",
    "hikke",
    "hikst",
    "hille",
    "hilse",
    "hilst",
    "himle",
    "hinds",
    "hinde",
    "hindi",
    "hindr",
    "hindu",
    "hinke",
    "hints",
    "hippe",
    "hipos",
    "hirds",
    "hirse",
    "hisse",
    "hitte",
    "hivet",
    "hevet",
    "hiver",
    "hives",
    "hjald",
    "hjelm",
    "hjems",
    "hjeml",
    "hjord",
    "hjort",
    "hjuls",
    "hjule",
    "hjælp",
    "hjalp",
    "hoben",
    "hobes",
    "hobby",
    "hobet",
    "hober",
    "hofte",
    "holds",
    "holde",
    "holdt",
    "holms",
    "holme",
    "homos",
    "hooks",
    "hopla",
    "hoppe",
    "hopsa",
    "horet",
    "horas",
    "horde",
    "horen",
    "hores",
    "horer",
    "horks",
    "horns",
    "horst",
    "hosen",
    "hoses",
    "hoser",
    "hosts",
    "hoste",
    "hotte",
    "hotel",
    "house",
    "hoven",
    "hoves",
    "hovet",
    "hoved",
    "hovne",
    "hover",
    "hovsa",
    "huens",
    "huden",
    "huder",
    "huers",
    "huede",
    "hugaf",
    "hugge",
    "hugst",
    "hujet",
    "hujer",
    "hujes",
    "hukke",
    "hulds",
    "hulde",
    "huldt",
    "hulen",
    "hules",
    "huler",
    "hulet",
    "hulks",
    "hulke",
    "hulle",
    "human",
    "humle",
    "humme",
    "humor",
    "humpe",
    "humus",
    "humør",
    "hende",
    "hunds",
    "hunde",
    "hungr",
    "huris",
    "hurra",
    "huset",
    "huses",
    "husar",
    "huser",
    "huske",
    "husly",
    "hutle",
    "hvals",
    "hvalp",
    "hvast",
    "hvede",
    "hvene",
    "hveps",
    "hvert",
    "hverv",
    "hvids",
    "hvide",
    "hvidt",
    "hvidn",
    "hvils",
    "hvile",
    "hvilt",
    "hvins",
    "hvine",
    "hvisk",
    "hvisl",
    "hvori",
    "hvælv",
    "hvæse",
    "hvæst",
    "hybel",
    "hyben",
    "hyfen",
    "hyfes",
    "hyfer",
    "hygge",
    "hykle",
    "hylet",
    "hylds",
    "hylde",
    "hyler",
    "hyles",
    "hylle",
    "hymen",
    "hymne",
    "hynde",
    "hypen",
    "hypes",
    "hypet",
    "hyper",
    "hyppe",
    "hyrde",
    "hyren",
    "hyres",
    "hyrer",
    "hyret",
    "hysse",
    "hytte",
    "hyæne",
    "hæder",
    "hædre",
    "hæfte",
    "hægen",
    "hægge",
    "hæges",
    "hæget",
    "hæger",
    "hægte",
    "hække",
    "hækle",
    "hælen",
    "hæles",
    "hælds",
    "hælde",
    "hældt",
    "hælet",
    "hæler",
    "hælvt",
    "hæmme",
    "hænde",
    "hændt",
    "hænge",
    "hængt",
    "hæren",
    "hæres",
    "hærde",
    "hærdn",
    "hærge",
    "hætte",
    "hævds",
    "hævde",
    "hævet",
    "hæver",
    "hæves",
    "hævns",
    "hævne",
    "høets",
    "høfde",
    "høgen",
    "høges",
    "højen",
    "højes",
    "højde",
    "højne",
    "højre",
    "højst",
    "høker",
    "hølæs",
    "hønen",
    "hønes",
    "høner",
    "hønse",
    "hører",
    "hørte",
    "høres",
    "hørms",
    "hørme",
    "høsts",
    "høste",
    "høtyv",
    "høved",
    "høvls",
    "høvle",
    "håbet",
    "håber",
    "håbes",
    "hånen",
    "hånds",
    "hånde",
    "hånet",
    "håner",
    "hånes",
    "hånle",
    "hånlo",
    "hånsk",
    "håret",
    "hårde",
    "hårdt",
    "jeres",
    "i alt",
    "icing",
    "i dag",
    "idéen",
    "idéer",
    "ideal",
    "ideel",
    "idiom",
    "idiot",
    "idols",
    "idræt",
    "idyls",
    "idømt",
    "ifald",
    "iføre",
    "iført",
    "iglen",
    "igles",
    "igler",
    "iglos",
    "i går",
    "ihjel",
    "ikend",
    "ikkun",
    "iklæd",
    "ikons",
    "ikten",
    "iktes",
    "ikter",
    "iland",
    "ilbud",
    "ilden",
    "ilder",
    "ildhu",
    "ildne",
    "ilede",
    "iling",
    "ilsom",
    "ilten",
    "iltet",
    "iltes",
    "ilter",
    "iltre",
    "iltog",
    "ilagt",
    "image",
    "imams",
    "imens",
    "immer",
    "immun",
    "inbox",
    "indad",
    "indbo",
    "inden",
    "inder",
    "indgå",
    "indie",
    "indre",
    "indse",
    "indså",
    "indsø",
    "indvi",
    "indøv",
    "inert",
    "infam",
    "infos",
    "ingen",
    "intet",
    "inkas",
    "input",
    "intim",
    "intro",
    "inuit",
    "ionen",
    "ioner",
    "iotas",
    "irren",
    "irers",
    "irere",
    "irisk",
    "ironi",
    "irret",
    "irrer",
    "irres",
    "irske",
    "irøre",
    "irørt",
    "isens",
    "isene",
    "isbar",
    "isbod",
    "isbåd",
    "isede",
    "isfri",
    "ishav",
    "ising",
    "islag",
    "islam",
    "islom",
    "islæg",
    "islæt",
    "ismen",
    "ismes",
    "ismer",
    "isnet",
    "isner",
    "isnes",
    "isops",
    "issen",
    "isses",
    "isser",
    "issue",
    "issyl",
    "istap",
    "istes",
    "istem",
    "istid",
    "i stå",
    "isyet",
    "isyes",
    "isyer",
    "items",
    "ivers",
    "ivret",
    "ivrer",
    "ivres",
    "ivrig",
    "jaets",
    "jaers",
    "jabbe",
    "jaden",
    "jades",
    "jaget",
    "jager",
    "jages",
    "jagts",
    "jagte",
    "jaket",
    "jakke",
    "jambe",
    "jamen",
    "jamme",
    "jamre",
    "jante",
    "jappe",
    "jarls",
    "jasks",
    "jaske",
    "javel",
    "jazze",
    "jeans",
    "jeeps",
    "jeget",
    "jeger",
    "jenka",
    "jerns",
    "jeton",
    "jihad",
    "jiven",
    "jives",
    "jobbe",
    "joden",
    "jodet",
    "jodle",
    "jogge",
    "joint",
    "joken",
    "jokes",
    "joket",
    "joker",
    "jokke",
    "jolle",
    "jolre",
    "jonen",
    "joner",
    "jords",
    "jorde",
    "jotas",
    "joule",
    "jours",
    "jubel",
    "juble",
    "judas",
    "judos",
    "juice",
    "julen",
    "jules",
    "julet",
    "juler",
    "julis",
    "jumbe",
    "junge",
    "junis",
    "junks",
    "junke",
    "junta",
    "juras",
    "jurys",
    "juten",
    "jutes",
    "juvel",
    "jyden",
    "jydes",
    "jyder",
    "jysks",
    "jyske",
    "jæger",
    "jærpe",
    "jærvs",
    "jærve",
    "jætte",
    "jævne",
    "jævnt",
    "jøden",
    "jødes",
    "jøder",
    "jøkel",
    "kabel",
    "kabys",
    "kadet",
    "kadis",
    "kadre",
    "kaffe",
    "kagen",
    "kages",
    "kager",
    "kagls",
    "kagle",
    "kahyt",
    "kajen",
    "kajer",
    "kajak",
    "kajes",
    "kakao",
    "kakis",
    "kalas",
    "kalds",
    "kalde",
    "kaldt",
    "kalis",
    "kalif",
    "kalks",
    "kalke",
    "kalla",
    "kalot",
    "kalvs",
    "kalve",
    "kamme",
    "kamés",
    "kamel",
    "kamik",
    "kamin",
    "kamre",
    "kamps",
    "kampe",
    "kanal",
    "kande",
    "kanen",
    "kanes",
    "kaner",
    "kanel",
    "kanin",
    "kanos",
    "kanon",
    "kants",
    "kante",
    "kanut",
    "kapel",
    "kaper",
    "kapok",
    "kappa",
    "kappe",
    "kapre",
    "kapsl",
    "kapun",
    "kaput",
    "karat",
    "karet",
    "kargo",
    "karls",
    "karle",
    "karms",
    "karme",
    "karma",
    "karpe",
    "karré",
    "karry",
    "karse",
    "karsk",
    "karte",
    "karve",
    "kasak",
    "kasba",
    "kasko",
    "kasse",
    "kasts",
    "kaste",
    "kasus",
    "katte",
    "katar",
    "kavaj",
    "kazoo",
    "kebab",
    "kedet",
    "keder",
    "kedes",
    "kedel",
    "kefir",
    "kegle",
    "kelen",
    "keles",
    "kelet",
    "keler",
    "kelim",
    "kemis",
    "kemos",
    "kende",
    "kendt",
    "kepis",
    "keret",
    "kerer",
    "keres",
    "kerne",
    "kerte",
    "kerub",
    "ketch",
    "keton",
    "khans",
    "khiet",
    "khier",
    "khmer",
    "kicks",
    "kigge",
    "kikke",
    "kikse",
    "kilde",
    "kildr",
    "kilen",
    "kiles",
    "kiler",
    "kilet",
    "kilos",
    "kilts",
    "kilte",
    "kimen",
    "kimet",
    "kimer",
    "kimes",
    "kimse",
    "kinds",
    "kinin",
    "kinky",
    "kinos",
    "kiosk",
    "kiper",
    "kippa",
    "kippe",
    "kirke",
    "kisen",
    "kisel",
    "kiste",
    "kitin",
    "kitte",
    "kiven",
    "kives",
    "kiwis",
    "kjole",
    "kjove",
    "klage",
    "klamt",
    "klamp",
    "klamr",
    "klans",
    "klang",
    "klaps",
    "klapr",
    "klare",
    "klart",
    "klase",
    "klask",
    "klats",
    "klatr",
    "klavr",
    "klejn",
    "klems",
    "klemt",
    "klerk",
    "klids",
    "kliks",
    "klike",
    "klima",
    "kline",
    "kling",
    "klink",
    "klint",
    "klips",
    "klirs",
    "klire",
    "klits",
    "kloen",
    "kløer",
    "kloak",
    "klode",
    "klods",
    "kloge",
    "klogt",
    "klons",
    "klone",
    "klors",
    "klore",
    "klovs",
    "klove",
    "klovn",
    "klubs",
    "kluds",
    "klude",
    "kludr",
    "kluks",
    "klump",
    "klumr",
    "kluns",
    "klups",
    "klyde",
    "klyng",
    "klynk",
    "klæbe",
    "klæde",
    "klædt",
    "klægs",
    "klæge",
    "klægt",
    "kløet",
    "kløen",
    "kløes",
    "kløft",
    "kløgt",
    "klørs",
    "kløve",
    "knags",
    "knage",
    "knald",
    "knaps",
    "knapt",
    "knark",
    "knarr",
    "knase",
    "knast",
    "knebs",
    "knebl",
    "knebr",
    "knejs",
    "knevr",
    "knibe",
    "kniks",
    "knipl",
    "knips",
    "knirk",
    "knitr",
    "knivs",
    "knive",
    "knoen",
    "knoer",
    "knobs",
    "knokl",
    "knold",
    "knops",
    "knubs",
    "knude",
    "knugs",
    "knuge",
    "knuse",
    "knust",
    "knyet",
    "knyes",
    "knyer",
    "knyst",
    "knæet",
    "knægt",
    "knæks",
    "knæle",
    "knøse",
    "koens",
    "køers",
    "koala",
    "koben",
    "koble",
    "kobra",
    "koden",
    "kodes",
    "koder",
    "kodet",
    "kofte",
    "kogen",
    "koges",
    "koger",
    "kogte",
    "kogeø",
    "kogge",
    "kogle",
    "kohls",
    "kokke",
    "koket",
    "kokon",
    "kokos",
    "kokse",
    "kolbe",
    "kolde",
    "koldt",
    "kolik",
    "kolli",
    "kolon",
    "kolos",
    "komas",
    "komet",
    "komik",
    "komma",
    "komme",
    "kondi",
    "konen",
    "kones",
    "koner",
    "konet",
    "konge",
    "konks",
    "konto",
    "konti",
    "konus",
    "kopar",
    "kopek",
    "kopis",
    "kopra",
    "koret",
    "koral",
    "koran",
    "korde",
    "korks",
    "korns",
    "korps",
    "korse",
    "korts",
    "korte",
    "kosak",
    "kosts",
    "koste",
    "koten",
    "kotes",
    "koter",
    "koøje",
    "kraal",
    "krabl",
    "krads",
    "kraft",
    "krage",
    "kraks",
    "krams",
    "kramp",
    "krans",
    "krank",
    "kraps",
    "krapt",
    "krast",
    "krats",
    "kravs",
    "krave",
    "kravl",
    "krebs",
    "kreds",
    "kreer",
    "krejl",
    "kremt",
    "kreol",
    "kribl",
    "kridt",
    "krigs",
    "krige",
    "krilr",
    "krimi",
    "krise",
    "kroen",
    "kroer",
    "kroet",
    "kroat",
    "krogs",
    "kroge",
    "krolf",
    "kroms",
    "krone",
    "krops",
    "krudt",
    "kruks",
    "krumt",
    "kruse",
    "krybs",
    "krybe",
    "krydr",
    "kryds",
    "krymp",
    "krypt",
    "kryst",
    "kræet",
    "kræft",
    "kræge",
    "kræng",
    "krænk",
    "kræse",
    "kræve",
    "krøls",
    "kråls",
    "kråse",
    "ksiet",
    "ksier",
    "kuben",
    "kubes",
    "kuber",
    "kubik",
    "kubus",
    "kudus",
    "kuede",
    "kugle",
    "kujon",
    "kukke",
    "kukur",
    "kulak",
    "kulds",
    "kulde",
    "kulen",
    "kules",
    "kuler",
    "kulet",
    "kulis",
    "kulos",
    "kulso",
    "kults",
    "kulør",
    "kumme",
    "kunde",
    "kunne",
    "kunst",
    "kupés",
    "kuper",
    "kuple",
    "kupon",
    "kuppe",
    "kuren",
    "kures",
    "kuret",
    "kurer",
    "kurie",
    "kurre",
    "kurvs",
    "kurve",
    "kusks",
    "kuske",
    "kusse",
    "kutte",
    "kvads",
    "kvajs",
    "kvaje",
    "kvals",
    "kvalm",
    "kvans",
    "kvant",
    "kvark",
    "kvart",
    "kvase",
    "kvast",
    "kvejl",
    "kvide",
    "kvidr",
    "kvien",
    "kvies",
    "kvier",
    "kviet",
    "kviks",
    "kvikt",
    "kvilt",
    "kvint",
    "kvist",
    "kvote",
    "kvæde",
    "kvægs",
    "kvæge",
    "kvæks",
    "kvæld",
    "kvæle",
    "kvalt",
    "kværk",
    "kværn",
    "kvæst",
    "kylet",
    "kyler",
    "kyles",
    "kyper",
    "kyras",
    "kyrie",
    "kysen",
    "kyses",
    "kyser",
    "kyset",
    "kyste",
    "kyskt",
    "kyske",
    "kysse",
    "kysts",
    "kæben",
    "kæbes",
    "kæber",
    "kæden",
    "kædes",
    "kæder",
    "kædet",
    "kæfts",
    "kæfte",
    "kække",
    "kælet",
    "kæler",
    "kæles",
    "kælen",
    "kælne",
    "kælks",
    "kælke",
    "kælve",
    "kæmme",
    "kæmpe",
    "kæntr",
    "kæppe",
    "kæret",
    "kæres",
    "kærer",
    "kærne",
    "kærre",
    "kærte",
    "kærvs",
    "kærve",
    "kævet",
    "kæver",
    "kæves",
    "kævls",
    "kævle",
    "køens",
    "købet",
    "køber",
    "købte",
    "købes",
    "kødet",
    "køjen",
    "køjes",
    "køjer",
    "kølen",
    "køles",
    "kølet",
    "køler",
    "kølig",
    "kølle",
    "kølne",
    "kønne",
    "køres",
    "kører",
    "kørte",
    "køter",
    "kåben",
    "kåbes",
    "kåber",
    "kågen",
    "kåges",
    "kålen",
    "kårde",
    "kåret",
    "kårer",
    "kåres",
    "laets",
    "laers",
    "laban",
    "labbe",
    "label",
    "laber",
    "labre",
    "labil",
    "ladet",
    "laden",
    "lades",
    "lader",
    "ladys",
    "laget",
    "lagen",
    "lages",
    "lager",
    "lagre",
    "lakaj",
    "laker",
    "lakke",
    "lalle",
    "lamme",
    "lamas",
    "lamés",
    "lamel",
    "lampe",
    "lands",
    "lande",
    "lange",
    "langt",
    "langs",
    "lanse",
    "laots",
    "lapis",
    "lappe",
    "lapse",
    "large",
    "largo",
    "larms",
    "larme",
    "larve",
    "lasen",
    "laser",
    "laset",
    "lasks",
    "laske",
    "lassi",
    "lasso",
    "lasts",
    "laste",
    "lasur",
    "latex",
    "latin",
    "latte",
    "lauds",
    "laven",
    "lavet",
    "laver",
    "lavas",
    "laves",
    "leens",
    "leers",
    "lease",
    "leben",
    "leden",
    "ledes",
    "ledet",
    "leder",
    "ledte",
    "ledig",
    "lefle",
    "legen",
    "leges",
    "legal",
    "legat",
    "leget",
    "leger",
    "legio",
    "lejde",
    "lejen",
    "lejes",
    "lejer",
    "lejet",
    "lejrs",
    "lejre",
    "lemme",
    "lemma",
    "lempe",
    "lemur",
    "lenet",
    "lento",
    "leret",
    "lette",
    "letal",
    "leuen",
    "leven",
    "levas",
    "leves",
    "levet",
    "lever",
    "levit",
    "levns",
    "levne",
    "levre",
    "liens",
    "liers",
    "lians",
    "lider",
    "lides",
    "liden",
    "lidet",
    "lidos",
    "lidse",
    "lieds",
    "liere",
    "lifts",
    "lifte",
    "liget",
    "ligas",
    "liges",
    "ligge",
    "light",
    "ligne",
    "liket",
    "likes",
    "liker",
    "likør",
    "lilje",
    "lilla",
    "lille",
    "limen",
    "limes",
    "limbo",
    "limet",
    "limer",
    "limit",
    "linet",
    "linds",
    "linde",
    "lindt",
    "lindr",
    "linen",
    "lines",
    "liner",
    "linje",
    "links",
    "linke",
    "linse",
    "lipid",
    "liren",
    "lires",
    "lirer",
    "liret",
    "lirke",
    "lises",
    "lists",
    "liste",
    "liter",
    "litra",
    "livet",
    "liver",
    "lives",
    "livré",
    "lixen",
    "lixet",
    "lixer",
    "lixes",
    "loens",
    "loers",
    "loade",
    "lobbe",
    "lobby",
    "lodde",
    "lodne",
    "loden",
    "lodse",
    "lofts",
    "logge",
    "logen",
    "loges",
    "loger",
    "logis",
    "logik",
    "login",
    "logos",
    "logon",
    "logre",
    "lokal",
    "lokke",
    "lokum",
    "lomme",
    "lomvi",
    "longe",
    "looks",
    "loops",
    "loope",
    "loppe",
    "lords",
    "loren",
    "lorne",
    "lorts",
    "lorte",
    "losse",
    "lotte",
    "lotto",
    "lotus",
    "loven",
    "loves",
    "lovet",
    "lover",
    "loyal",
    "lubbe",
    "luden",
    "ludet",
    "luder",
    "ludes",
    "ludos",
    "luens",
    "luers",
    "luede",
    "luffe",
    "lufts",
    "lufte",
    "lugar",
    "lugen",
    "luges",
    "luger",
    "luget",
    "lugts",
    "lugte",
    "lukaf",
    "lukke",
    "lulle",
    "lumen",
    "lumre",
    "lumsk",
    "lunch",
    "lunds",
    "lunde",
    "lunet",
    "lunes",
    "luner",
    "lunge",
    "lunke",
    "lunte",
    "lupin",
    "luren",
    "lurer",
    "lures",
    "luret",
    "lusen",
    "luset",
    "lusks",
    "luske",
    "lutre",
    "luven",
    "luvet",
    "luver",
    "luves",
    "lyets",
    "lyene",
    "lybsk",
    "lyden",
    "lydes",
    "lyder",
    "lydig",
    "lygte",
    "lykke",
    "lymfe",
    "lynet",
    "lynch",
    "lyner",
    "lynes",
    "lyngs",
    "lyren",
    "lyres",
    "lyrer",
    "lyrik",
    "lyset",
    "lyser",
    "lyste",
    "lyses",
    "lyske",
    "lysne",
    "lysts",
    "lystr",
    "lysår",
    "lytte",
    "løjet",
    "lyver",
    "lyves",
    "læets",
    "læede",
    "læben",
    "læbes",
    "læber",
    "læder",
    "lægge",
    "lægds",
    "lægen",
    "læges",
    "læger",
    "læget",
    "lægte",
    "lagde",
    "lægtr",
    "lække",
    "lækat",
    "lækre",
    "læmme",
    "lænds",
    "lænet",
    "læner",
    "lænes",
    "længe",
    "lænke",
    "lænse",
    "lærde",
    "læren",
    "læres",
    "lærer",
    "lærte",
    "lærks",
    "lærke",
    "læser",
    "læste",
    "læses",
    "læske",
    "læsps",
    "læspe",
    "læsse",
    "læsts",
    "løbet",
    "løben",
    "løbes",
    "løber",
    "løbne",
    "løbsk",
    "løden",
    "lødig",
    "løfts",
    "løfte",
    "løget",
    "løgns",
    "løgne",
    "løjen",
    "løjes",
    "løjer",
    "løjpe",
    "løkke",
    "lønne",
    "løser",
    "løste",
    "løses",
    "løsen",
    "løsne",
    "løvet",
    "løven",
    "løves",
    "løver",
    "låget",
    "lågen",
    "låges",
    "låger",
    "lånet",
    "låner",
    "lånte",
    "lånes",
    "låret",
    "låsen",
    "låses",
    "låset",
    "låser",
    "låste",
    "machs",
    "macho",
    "maden",
    "madet",
    "mader",
    "mades",
    "madro",
    "mafia",
    "magen",
    "mages",
    "mager",
    "maget",
    "magre",
    "magis",
    "magma",
    "magts",
    "magte",
    "mails",
    "maile",
    "majet",
    "majer",
    "majes",
    "major",
    "makis",
    "makke",
    "makro",
    "malaj",
    "malet",
    "maler",
    "malte",
    "males",
    "malke",
    "malle",
    "malms",
    "malme",
    "malts",
    "malør",
    "mamas",
    "mamba",
    "mambo",
    "manen",
    "maner",
    "mands",
    "mænds",
    "mande",
    "manet",
    "manes",
    "manér",
    "manga",
    "mange",
    "mangt",
    "mangl",
    "mango",
    "manis",
    "manke",
    "manko",
    "manna",
    "manne",
    "manus",
    "maori",
    "mappe",
    "march",
    "maren",
    "mares",
    "marer",
    "marin",
    "marks",
    "marsk",
    "martr",
    "marts",
    "marvs",
    "maset",
    "masai",
    "maser",
    "maste",
    "mases",
    "masks",
    "maske",
    "masse",
    "masts",
    "maten",
    "mater",
    "matte",
    "match",
    "matés",
    "maven",
    "maves",
    "maver",
    "mavet",
    "maxis",
    "mayas",
    "meden",
    "medes",
    "meder",
    "medet",
    "medgå",
    "medie",
    "medio",
    "media",
    "medsø",
    "megen",
    "meget",
    "mejet",
    "mejer",
    "mejes",
    "mejse",
    "mejsl",
    "mekka",
    "melet",
    "melde",
    "meldt",
    "meler",
    "meles",
    "melis",
    "melon",
    "memet",
    "memes",
    "memer",
    "memos",
    "mener",
    "mente",
    "menes",
    "mened",
    "menig",
    "menus",
    "mergl",
    "merit",
    "mesan",
    "messe",
    "meste",
    "mestr",
    "metal",
    "metan",
    "meter",
    "metro",
    "metra",
    "metre",
    "metyl",
    "mezzo",
    "miets",
    "miers",
    "miave",
    "miden",
    "mides",
    "mider",
    "midje",
    "midte",
    "midti",
    "mikse",
    "milen",
    "milde",
    "mildt",
    "mildn",
    "miles",
    "miler",
    "miljø",
    "milts",
    "milte",
    "mimen",
    "mimes",
    "mimer",
    "mimet",
    "mimik",
    "mimre",
    "minde",
    "minen",
    "mines",
    "miner",
    "mingl",
    "minis",
    "minks",
    "mints",
    "minus",
    "minut",
    "minør",
    "misos",
    "misse",
    "miste",
    "mitra",
    "mitte",
    "mixen",
    "mixet",
    "mixer",
    "mixes",
    "mjave",
    "mjøds",
    "mobbe",
    "mobil",
    "modet",
    "modal",
    "moden",
    "modes",
    "moder",
    "model",
    "modem",
    "modne",
    "mødre",
    "modgå",
    "modig",
    "modsø",
    "modul",
    "modus",
    "modis",
    "moiré",
    "mokka",
    "mokke",
    "molet",
    "moler",
    "molbo",
    "molen",
    "moles",
    "molok",
    "molær",
    "momse",
    "monne",
    "monos",
    "moppe",
    "moren",
    "morer",
    "morak",
    "moral",
    "mords",
    "moret",
    "mores",
    "morel",
    "morfs",
    "moros",
    "morse",
    "mosen",
    "moses",
    "moser",
    "moset",
    "moské",
    "mosle",
    "mosts",
    "moste",
    "motel",
    "motet",
    "motiv",
    "motor",
    "motto",
    "mover",
    "mudre",
    "muffe",
    "mufti",
    "muget",
    "muger",
    "muges",
    "mugne",
    "muhko",
    "mukke",
    "mulat",
    "mulds",
    "muldn",
    "mulen",
    "mules",
    "muler",
    "mulet",
    "mulig",
    "mulkt",
    "mulle",
    "mulms",
    "mults",
    "multe",
    "mumie",
    "mumle",
    "munds",
    "munde",
    "munks",
    "munke",
    "muntr",
    "muren",
    "mures",
    "murbi",
    "muret",
    "murer",
    "murre",
    "musen",
    "muses",
    "muser",
    "muset",
    "musik",
    "musts",
    "mutte",
    "muter",
    "muzak",
    "myets",
    "myers",
    "myldr",
    "mynde",
    "mynte",
    "myose",
    "myren",
    "myrer",
    "myrde",
    "myres",
    "myret",
    "myrra",
    "myrte",
    "mysli",
    "myten",
    "mytes",
    "myter",
    "mæcen",
    "mægle",
    "mægte",
    "mæhæs",
    "mælde",
    "mælet",
    "mæles",
    "mæler",
    "mælte",
    "mælks",
    "mælke",
    "mænge",
    "mæren",
    "mæres",
    "mærke",
    "mæsks",
    "mæske",
    "mætte",
    "møens",
    "møers",
    "møbel",
    "mødet",
    "mødes",
    "møder",
    "mødte",
    "mødig",
    "mødom",
    "møffe",
    "møget",
    "møger",
    "møges",
    "møgso",
    "møjen",
    "møjes",
    "mølle",
    "mønbo",
    "mønje",
    "mønne",
    "mønsk",
    "mønts",
    "mønte",
    "mørke",
    "mørkt",
    "mørkn",
    "mørne",
    "møvet",
    "møver",
    "møves",
    "måbet",
    "måber",
    "måbes",
    "måden",
    "mådes",
    "måder",
    "mågen",
    "måges",
    "måger",
    "målet",
    "måler",
    "målte",
    "måles",
    "månen",
    "månes",
    "måner",
    "måned",
    "måren",
    "mårer",
    "måres",
    "måsen",
    "måses",
    "måske",
    "måtte",
    "nabos",
    "nadir",
    "nafta",
    "naget",
    "nager",
    "nages",
    "nagle",
    "naive",
    "naivt",
    "najet",
    "najer",
    "najes",
    "nakke",
    "nandu",
    "nappa",
    "nappe",
    "narre",
    "narko",
    "narvs",
    "nasal",
    "nasse",
    "natur",
    "navet",
    "naver",
    "navle",
    "navns",
    "navne",
    "navrs",
    "navre",
    "nazis",
    "nedad",
    "neden",
    "ned i",
    "nedis",
    "nedom",
    "nedre",
    "neget",
    "neger",
    "negre",
    "negls",
    "negle",
    "nejet",
    "nejer",
    "nejes",
    "nemme",
    "neons",
    "nerie",
    "nertz",
    "nerve",
    "neste",
    "nette",
    "netop",
    "netto",
    "nevøs",
    "niche",
    "niece",
    "niers",
    "niere",
    "nikke",
    "ninja",
    "nippe",
    "nipse",
    "niqab",
    "nisse",
    "nists",
    "nital",
    "nitis",
    "nitte",
    "nevet",
    "niver",
    "nives",
    "niøje",
    "niårs",
    "nobel",
    "noble",
    "noden",
    "nodes",
    "noder",
    "nogen",
    "noget",
    "nogle",
    "nolet",
    "noler",
    "noles",
    "nolos",
    "nomen",
    "nonen",
    "nones",
    "noner",
    "nonet",
    "nonne",
    "noppe",
    "nopre",
    "noret",
    "nords",
    "norms",
    "norne",
    "norsk",
    "nosse",
    "noten",
    "noter",
    "notet",
    "notas",
    "notar",
    "notat",
    "notes",
    "novas",
    "nuets",
    "nubre",
    "nudel",
    "nulre",
    "nulte",
    "numre",
    "numse",
    "nuppe",
    "nurse",
    "nuser",
    "nusle",
    "nusse",
    "nutid",
    "nuvel",
    "nyets",
    "nyers",
    "nydan",
    "nyder",
    "nydes",
    "nyhed",
    "nykke",
    "nylig",
    "nylon",
    "nymfe",
    "nynne",
    "nyren",
    "nyres",
    "nyrer",
    "nyrig",
    "nyset",
    "nyser",
    "nyste",
    "nyses",
    "nysne",
    "nysyn",
    "nytte",
    "nytår",
    "næets",
    "næbet",
    "næbes",
    "nægte",
    "nælde",
    "nænne",
    "næppe",
    "næret",
    "nærer",
    "næres",
    "nærig",
    "nærme",
    "nærpå",
    "næsen",
    "næses",
    "næser",
    "næste",
    "næven",
    "næves",
    "næver",
    "nævns",
    "nævne",
    "nævnt",
    "nøden",
    "nødet",
    "nøder",
    "nødte",
    "nødes",
    "nødig",
    "nøgen",
    "nøgne",
    "nøgle",
    "nøjes",
    "nøkke",
    "nølet",
    "nøler",
    "nøles",
    "nørds",
    "nørde",
    "nørkl",
    "nåede",
    "nåden",
    "nådes",
    "nådig",
    "nålen",
    "nåles",
    "oasen",
    "oases",
    "oaser",
    "oasis",
    "oblat",
    "oblik",
    "oboen",
    "oboer",
    "ocean",
    "odden",
    "oddes",
    "odder",
    "odens",
    "oders",
    "odiøs",
    "odørs",
    "offer",
    "ofret",
    "ofres",
    "ofrer",
    "okapi",
    "okker",
    "okras",
    "oksen",
    "okses",
    "okser",
    "okset",
    "oktan",
    "oktav",
    "oktet",
    "olens",
    "olene",
    "olden",
    "olien",
    "olies",
    "olier",
    "olymp",
    "ombud",
    "ombuk",
    "ombyg",
    "ombyt",
    "ombær",
    "ombar",
    "ombøj",
    "omdan",
    "omdel",
    "omdøb",
    "omega",
    "omegn",
    "omens",
    "omend",
    "omfat",
    "omgiv",
    "omgav",
    "omgør",
    "omgås",
    "omgår",
    "omgik",
    "omhus",
    "omhyl",
    "omkod",
    "omkom",
    "omlad",
    "omlyd",
    "omlæg",
    "omlæs",
    "omløb",
    "ommer",
    "ompot",
    "omram",
    "omrør",
    "omsæt",
    "omsat",
    "omtal",
    "omvej",
    "onani",
    "ondet",
    "ondes",
    "onder",
    "onkel",
    "onyks",
    "op ad",
    "opake",
    "opakt",
    "opals",
    "opart",
    "opbag",
    "opbud",
    "opbyd",
    "opbød",
    "opbyg",
    "opdag",
    "opdel",
    "opdæm",
    "opera",
    "opfat",
    "opfed",
    "opfej",
    "opfør",
    "opgiv",
    "opgav",
    "opgør",
    "ophav",
    "ophed",
    "ophob",
    "ophug",
    "ophæv",
    "ophøj",
    "ophør",
    "opiat",
    "opium",
    "opkog",
    "opkom",
    "opkøb",
    "oplad",
    "oplod",
    "oplag",
    "oplev",
    "opliv",
    "oplys",
    "oplæg",
    "oplær",
    "oplæs",
    "opløb",
    "opløs",
    "opmål",
    "opnås",
    "opnår",
    "opofr",
    "oppet",
    "opper",
    "oppes",
    "opret",
    "oprul",
    "opryk",
    "oprør",
    "opråb",
    "opsat",
    "opsig",
    "opslå",
    "opstå",
    "opsug",
    "opsyn",
    "opsæt",
    "opsøg",
    "optag",
    "optog",
    "opter",
    "optik",
    "optil",
    "optur",
    "optæl",
    "optøs",
    "optør",
    "opvej",
    "opvis",
    "opvæk",
    "opæde",
    "opædt",
    "opøve",
    "orals",
    "orale",
    "oralt",
    "ordet",
    "orden",
    "ordne",
    "ordre",
    "organ",
    "orgel",
    "orgie",
    "orken",
    "orker",
    "orkan",
    "orket",
    "orkes",
    "orkis",
    "orlon",
    "orlov",
    "ormen",
    "ormes",
    "ormet",
    "ormer",
    "ornat",
    "ornen",
    "ornes",
    "orner",
    "osens",
    "osede",
    "ostes",
    "osers",
    "osere",
    "osman",
    "osten",
    "ostet",
    "oster",
    "otium",
    "otiet",
    "ottes",
    "otter",
    "ottis",
    "ounce",
    "outet",
    "outer",
    "outes",
    "ouzos",
    "ovals",
    "ovale",
    "ovalt",
    "oveni",
    "overs",
    "overe",
    "ovnen",
    "ovnes",
    "oxids",
    "ozons",
    "pacen",
    "paces",
    "pacet",
    "pacer",
    "padde",
    "padle",
    "paffe",
    "pagaj",
    "pagen",
    "pages",
    "pager",
    "pagts",
    "paket",
    "pakis",
    "pakke",
    "palen",
    "paler",
    "palet",
    "palle",
    "palme",
    "palpe",
    "palæs",
    "pampa",
    "panda",
    "pande",
    "panel",
    "paner",
    "panik",
    "pansr",
    "pants",
    "pante",
    "panty",
    "papas",
    "papil",
    "papir",
    "parat",
    "parés",
    "parer",
    "paris",
    "paria",
    "parks",
    "parka",
    "parre",
    "parse",
    "parts",
    "parti",
    "party",
    "paryk",
    "pasha",
    "passe",
    "passé",
    "pasta",
    "patés",
    "pater",
    "patio",
    "patos",
    "patte",
    "pauke",
    "pause",
    "paven",
    "paves",
    "paver",
    "peake",
    "peber",
    "pebre",
    "pedal",
    "pedel",
    "peele",
    "peers",
    "peget",
    "peger",
    "peges",
    "pejle",
    "pejse",
    "pelse",
    "penne",
    "pendl",
    "penge",
    "penis",
    "penny",
    "pensl",
    "pensa",
    "peppe",
    "perle",
    "perms",
    "perse",
    "pesos",
    "pests",
    "pesto",
    "petit",
    "pette",
    "piets",
    "piers",
    "piano",
    "pibet",
    "piben",
    "pibes",
    "piber",
    "pebet",
    "pible",
    "piens",
    "piffe",
    "pifts",
    "pifte",
    "pigge",
    "pigen",
    "piges",
    "piger",
    "piget",
    "pikke",
    "pikés",
    "piker",
    "pilen",
    "piles",
    "pilaf",
    "pilet",
    "piler",
    "pilks",
    "pilke",
    "pille",
    "pilne",
    "pilot",
    "pilts",
    "pilte",
    "pimpe",
    "pinds",
    "pinde",
    "pinen",
    "pines",
    "piner",
    "pinte",
    "pings",
    "pinje",
    "pinol",
    "pinse",
    "pinup",
    "pippe",
    "pique",
    "piqué",
    "pirat",
    "pirks",
    "pirke",
    "pirog",
    "pirol",
    "pirre",
    "pisks",
    "piske",
    "pisse",
    "pists",
    "piste",
    "pitch",
    "pivot",
    "pixel",
    "pizza",
    "pjadr",
    "pjalt",
    "pjank",
    "pjask",
    "pjats",
    "pjece",
    "pjevs",
    "pjoks",
    "pjusk",
    "pjæks",
    "plade",
    "pladr",
    "plads",
    "plafs",
    "plags",
    "plage",
    "plaid",
    "plaks",
    "plans",
    "plane",
    "plant",
    "plank",
    "plano",
    "plapr",
    "plask",
    "plast",
    "plats",
    "plebs",
    "pleje",
    "plejl",
    "plets",
    "plien",
    "pligt",
    "plint",
    "plire",
    "plots",
    "plovs",
    "plove",
    "pludr",
    "pluks",
    "plump",
    "plumr",
    "plutr",
    "plæne",
    "pløje",
    "pløks",
    "pløre",
    "pløse",
    "poche",
    "poden",
    "podes",
    "poder",
    "podet",
    "podie",
    "poems",
    "poesi",
    "poets",
    "pogen",
    "poges",
    "point",
    "pokal",
    "poker",
    "polen",
    "poler",
    "polak",
    "polar",
    "polet",
    "polio",
    "polka",
    "polos",
    "polsk",
    "polts",
    "polte",
    "polyp",
    "polær",
    "pomet",
    "pomps",
    "ponds",
    "ponys",
    "pools",
    "popen",
    "popes",
    "poper",
    "popos",
    "poppe",
    "poren",
    "pores",
    "porer",
    "poret",
    "porno",
    "porre",
    "porse",
    "ports",
    "porte",
    "porto",
    "porøs",
    "posen",
    "poses",
    "poser",
    "poset",
    "posts",
    "poste",
    "posør",
    "poten",
    "potes",
    "poter",
    "potte",
    "power",
    "pragt",
    "prajs",
    "praje",
    "prals",
    "prale",
    "prams",
    "prang",
    "prens",
    "prene",
    "prent",
    "priks",
    "prikl",
    "prims",
    "prima",
    "prime",
    "primo",
    "prins",
    "print",
    "prion",
    "prior",
    "prise",
    "prist",
    "profs",
    "proft",
    "props",
    "prosa",
    "provo",
    "prudt",
    "prude",
    "prunk",
    "prust",
    "pruts",
    "pryds",
    "pryde",
    "prygl",
    "præet",
    "præer",
    "prægs",
    "præge",
    "præks",
    "præke",
    "præst",
    "prøve",
    "pråse",
    "psiet",
    "psier",
    "psyke",
    "pucks",
    "puden",
    "pudes",
    "puder",
    "pudre",
    "pudse",
    "puffe",
    "puget",
    "puger",
    "puges",
    "pukke",
    "pukle",
    "pulds",
    "pulde",
    "pulet",
    "puler",
    "pules",
    "pulje",
    "pulks",
    "pulke",
    "pulle",
    "pulps",
    "pulse",
    "pults",
    "pulte",
    "pumas",
    "pumps",
    "pumpe",
    "punch",
    "punds",
    "pungs",
    "punge",
    "punks",
    "punke",
    "punkt",
    "punsl",
    "pupil",
    "puppe",
    "purés",
    "purer",
    "purks",
    "purke",
    "purre",
    "purse",
    "pushe",
    "pusle",
    "pusts",
    "puste",
    "putte",
    "putto",
    "putti",
    "pyens",
    "pyers",
    "pygmæ",
    "pylon",
    "pylre",
    "pynts",
    "pynte",
    "pyton",
    "pægls",
    "pægle",
    "pælen",
    "pæles",
    "pæons",
    "pæren",
    "pæres",
    "pærer",
    "pøbel",
    "pølen",
    "pøles",
    "pølle",
    "pølse",
    "pønse",
    "pøsen",
    "pøses",
    "pøset",
    "pøser",
    "påank",
    "påbud",
    "påbyd",
    "påbød",
    "pådut",
    "pådøm",
    "påfør",
    "pågår",
    "pågik",
    "påhit",
    "påhør",
    "påkom",
    "påkær",
    "påkør",
    "pålig",
    "pålæg",
    "på ny",
    "pånød",
    "påpeg",
    "påset",
    "påses",
    "påsås",
    "påser",
    "påske",
    "påstå",
    "påsyn",
    "påsæt",
    "påsat",
    "påtag",
    "påtog",
    "påtal",
    "påtår",
    "påvis",
    "queer",
    "quilt",
    "rabat",
    "rabbi",
    "rable",
    "racen",
    "races",
    "racer",
    "racet",
    "racks",
    "raden",
    "rader",
    "rades",
    "radar",
    "radio",
    "radix",
    "radon",
    "radså",
    "rafle",
    "rafte",
    "ragas",
    "raget",
    "rager",
    "rages",
    "raids",
    "raide",
    "raita",
    "rajah",
    "raket",
    "rakke",
    "rakle",
    "rakus",
    "ralle",
    "rally",
    "ramme",
    "ramle",
    "ramte",
    "rampe",
    "ranet",
    "ranch",
    "rands",
    "rande",
    "raner",
    "ranes",
    "rangs",
    "range",
    "rangl",
    "ranke",
    "rankt",
    "rappe",
    "rapid",
    "rapse",
    "raset",
    "raser",
    "rases",
    "raskt",
    "raske",
    "rasle",
    "rasps",
    "raspe",
    "rasts",
    "raste",
    "raten",
    "rates",
    "rater",
    "ratet",
    "ratio",
    "ravet",
    "raves",
    "raver",
    "ravns",
    "ravne",
    "rayon",
    "reets",
    "reers",
    "reale",
    "realt",
    "rebet",
    "reber",
    "rebes",
    "rebel",
    "rebus",
    "reces",
    "recks",
    "reden",
    "redes",
    "redde",
    "reder",
    "redte",
    "redet",
    "reels",
    "reelt",
    "regal",
    "regel",
    "reger",
    "regis",
    "regns",
    "regne",
    "rejen",
    "rejes",
    "rejer",
    "rejfs",
    "rejfe",
    "rejse",
    "rejst",
    "rekyl",
    "relæs",
    "remme",
    "remis",
    "remix",
    "remse",
    "renen",
    "rener",
    "rends",
    "rende",
    "rendt",
    "rense",
    "rente",
    "reols",
    "repos",
    "reset",
    "rests",
    "resæt",
    "resat",
    "rette",
    "retor",
    "retro",
    "retur",
    "revet",
    "revir",
    "revle",
    "revne",
    "revse",
    "revys",
    "rhoet",
    "rhoer",
    "riede",
    "ribbe",
    "riden",
    "rides",
    "rider",
    "redne",
    "ridse",
    "ridts",
    "riffs",
    "rifts",
    "rigge",
    "riget",
    "riges",
    "riger",
    "rigel",
    "rigid",
    "rille",
    "rimen",
    "rimet",
    "rimer",
    "rimes",
    "rimpe",
    "rimte",
    "rinde",
    "randt",
    "rings",
    "ringe",
    "ringl",
    "rinke",
    "rippe",
    "risen",
    "riset",
    "riser",
    "rises",
    "risle",
    "rispe",
    "rists",
    "riste",
    "riten",
    "rites",
    "riter",
    "ritte",
    "ritus",
    "rivet",
    "rival",
    "riven",
    "rives",
    "river",
    "reven",
    "roens",
    "roede",
    "roben",
    "robes",
    "rober",
    "robot",
    "robåd",
    "rocks",
    "rocke",
    "roden",
    "rodet",
    "rodes",
    "roder",
    "rodeo",
    "roers",
    "roere",
    "rogns",
    "rokke",
    "roker",
    "rolig",
    "rolle",
    "romas",
    "roman",
    "rombe",
    "romer",
    "rommy",
    "rondo",
    "roret",
    "rosen",
    "roses",
    "roser",
    "roste",
    "rosés",
    "roset",
    "rosin",
    "roter",
    "rotor",
    "rotte",
    "rouge",
    "rough",
    "rovet",
    "royal",
    "rubbe",
    "rubel",
    "rubin",
    "ruche",
    "ruden",
    "rudes",
    "ruder",
    "rudel",
    "rudet",
    "rugen",
    "rugby",
    "ruget",
    "ruger",
    "ruges",
    "ruins",
    "rulam",
    "rulle",
    "rumba",
    "rumle",
    "rumme",
    "rumpe",
    "runde",
    "rundt",
    "runen",
    "runes",
    "runer",
    "runge",
    "rupis",
    "ruren",
    "rurer",
    "rural",
    "rusen",
    "ruses",
    "ruser",
    "rushs",
    "rusks",
    "ruske",
    "rusts",
    "ruste",
    "ruten",
    "rutes",
    "ruter",
    "rutsj",
    "rutte",
    "ryets",
    "ryaen",
    "ryaer",
    "rydde",
    "rygge",
    "røgne",
    "røgen",
    "røget",
    "ryger",
    "ryges",
    "rygte",
    "rykke",
    "rylen",
    "ryles",
    "ryler",
    "rynke",
    "rypen",
    "rypes",
    "ryper",
    "ryste",
    "rytme",
    "ræbet",
    "ræber",
    "ræbes",
    "rædde",
    "række",
    "rakte",
    "rælig",
    "rænke",
    "ræret",
    "ræset",
    "ræser",
    "ræses",
    "ræson",
    "ræven",
    "ræves",
    "røbet",
    "røber",
    "røbes",
    "rødme",
    "rødne",
    "røfle",
    "røger",
    "røges",
    "røgts",
    "røgte",
    "rømme",
    "rømte",
    "rønne",
    "røret",
    "røren",
    "røres",
    "rører",
    "rørte",
    "rørig",
    "røræg",
    "røsen",
    "røses",
    "røser",
    "røsts",
    "røsti",
    "røven",
    "røves",
    "røvet",
    "røver",
    "råens",
    "råers",
    "ræers",
    "råbet",
    "råber",
    "råbte",
    "råbes",
    "råbuk",
    "rådet",
    "rådne",
    "råder",
    "rådes",
    "rådig",
    "rådyr",
    "rågen",
    "råges",
    "råger",
    "råhed",
    "råhus",
    "råhyg",
    "råkid",
    "rålam",
    "rånok",
    "råtræ",
    "sabel",
    "sable",
    "sadel",
    "sadle",
    "safir",
    "safts",
    "safte",
    "sagen",
    "sager",
    "sagas",
    "sagns",
    "sagos",
    "sagte",
    "sagtn",
    "sakes",
    "sakke",
    "sakse",
    "salen",
    "sales",
    "salat",
    "saldo",
    "saldi",
    "salep",
    "salgs",
    "salig",
    "salme",
    "salon",
    "salsa",
    "salts",
    "salte",
    "salto",
    "salut",
    "salve",
    "salær",
    "samba",
    "sambo",
    "samen",
    "sames",
    "samer",
    "samle",
    "samme",
    "sampl",
    "samsk",
    "samsø",
    "sands",
    "sande",
    "sandt",
    "saner",
    "sangs",
    "sange",
    "sanke",
    "sanse",
    "saren",
    "sargs",
    "sarge",
    "saris",
    "sarte",
    "satte",
    "satan",
    "satin",
    "satse",
    "satyr",
    "sauce",
    "sauna",
    "saven",
    "saves",
    "savet",
    "saver",
    "savls",
    "savle",
    "savns",
    "savne",
    "saxen",
    "saxer",
    "scene",
    "scone",
    "scoop",
    "score",
    "scrol",
    "sedan",
    "seede",
    "seers",
    "seere",
    "segls",
    "segle",
    "segne",
    "sejen",
    "sejer",
    "sejds",
    "sejls",
    "sejle",
    "sejrs",
    "sejre",
    "sekel",
    "sekst",
    "sekts",
    "selen",
    "seles",
    "seler",
    "selvs",
    "selve",
    "semit",
    "senat",
    "sende",
    "sendt",
    "senen",
    "senes",
    "sener",
    "senet",
    "sengs",
    "senge",
    "senil",
    "sepia",
    "seraf",
    "serge",
    "serie",
    "serif",
    "serum",
    "seras",
    "servs",
    "serve",
    "serøs",
    "setes",
    "sesam",
    "setup",
    "sexet",
    "sfære",
    "shags",
    "shahs",
    "sheik",
    "shiit",
    "shine",
    "shits",
    "shops",
    "shots",
    "shows",
    "shunt",
    "siens",
    "siers",
    "siede",
    "siben",
    "sidde",
    "siden",
    "sides",
    "sider",
    "sidst",
    "sifon",
    "siger",
    "sagde",
    "siges",
    "sigel",
    "sigma",
    "signe",
    "sigts",
    "sigte",
    "sikhs",
    "sikke",
    "sikre",
    "silds",
    "silde",
    "silet",
    "siler",
    "siles",
    "silke",
    "silos",
    "silur",
    "simre",
    "sinds",
    "sinde",
    "sinke",
    "sintr",
    "sinus",
    "sioux",
    "sippe",
    "sirat",
    "sirts",
    "sirup",
    "sisal",
    "sisus",
    "sitar",
    "siten",
    "sitet",
    "sites",
    "sitin",
    "sitre",
    "sivet",
    "siver",
    "sives",
    "sjaks",
    "sjakr",
    "sjals",
    "sjaps",
    "sjask",
    "sjats",
    "sjavs",
    "sjofl",
    "sjosk",
    "sjovs",
    "sjove",
    "sjovt",
    "sjuft",
    "sjusk",
    "sjægt",
    "sjækl",
    "sjæls",
    "sjæle",
    "skabs",
    "skabe",
    "skabt",
    "skade",
    "skaft",
    "skaks",
    "skakt",
    "skals",
    "skala",
    "skalk",
    "skalp",
    "skalt",
    "skams",
    "skank",
    "skare",
    "skarn",
    "skarp",
    "skarv",
    "skats",
    "skate",
    "skave",
    "skeen",
    "skeer",
    "skete",
    "skede",
    "skeje",
    "skejs",
    "skels",
    "skele",
    "skeln",
    "skema",
    "skien",
    "skibs",
    "skibe",
    "skids",
    "skide",
    "skidt",
    "skift",
    "skiks",
    "skilt",
    "skilr",
    "skiml",
    "skimt",
    "skins",
    "skind",
    "skive",
    "skjul",
    "skoen",
    "skoet",
    "skods",
    "skogr",
    "skoks",
    "skold",
    "skole",
    "skorp",
    "skort",
    "skose",
    "skots",
    "skovs",
    "skove",
    "skovl",
    "skrab",
    "skral",
    "skram",
    "skrap",
    "skrat",
    "skred",
    "skrid",
    "skrig",
    "skreg",
    "skrin",
    "skriv",
    "skrev",
    "skrog",
    "skrot",
    "skrus",
    "skrub",
    "skrud",
    "skrue",
    "skruk",
    "skryd",
    "skrød",
    "skræk",
    "skræl",
    "skræm",
    "skræp",
    "skræv",
    "skrås",
    "skråt",
    "skrår",
    "skrål",
    "skrån",
    "skubs",
    "skuds",
    "skude",
    "skuet",
    "skues",
    "skuer",
    "skule",
    "skulk",
    "skums",
    "skuml",
    "skumr",
    "skunk",
    "skurs",
    "skure",
    "skurk",
    "skurv",
    "skvat",
    "skvis",
    "skvæt",
    "skyen",
    "skyer",
    "skyet",
    "skyes",
    "skyde",
    "skudt",
    "skyls",
    "skyld",
    "skynd",
    "skype",
    "skyrs",
    "skyts",
    "skæft",
    "skægs",
    "skægt",
    "skæls",
    "skæld",
    "skælm",
    "skælv",
    "skæmt",
    "skænd",
    "skæne",
    "skænk",
    "skærs",
    "skære",
    "skært",
    "skærf",
    "skærm",
    "skærp",
    "skærv",
    "skæve",
    "skævt",
    "skøds",
    "skøde",
    "skøge",
    "skøjt",
    "skøns",
    "skønt",
    "skøre",
    "skørt",
    "skørn",
    "skåls",
    "skåle",
    "skåne",
    "skårs",
    "sladr",
    "slags",
    "slagt",
    "slams",
    "slang",
    "slank",
    "slant",
    "slapt",
    "slask",
    "slats",
    "slave",
    "slems",
    "slemt",
    "slesk",
    "slevs",
    "sleve",
    "slibe",
    "slice",
    "slids",
    "slide",
    "slidt",
    "slige",
    "sligt",
    "sliks",
    "slims",
    "slime",
    "slips",
    "slisk",
    "slofs",
    "slots",
    "slubs",
    "slubr",
    "sluds",
    "slude",
    "sludr",
    "sluge",
    "slugt",
    "slukn",
    "slums",
    "slump",
    "slumr",
    "slups",
    "slurk",
    "sluse",
    "sluts",
    "slyng",
    "slæbs",
    "slæbe",
    "slæbt",
    "slæde",
    "slægt",
    "slækt",
    "slæng",
    "slæts",
    "sløje",
    "sløjt",
    "sløjd",
    "sløjf",
    "slørs",
    "sløre",
    "sløse",
    "sløve",
    "sløvt",
    "slåen",
    "slåer",
    "slået",
    "smadr",
    "smags",
    "smage",
    "smagt",
    "smalt",
    "small",
    "smaln",
    "smart",
    "smash",
    "smask",
    "smats",
    "smear",
    "smeds",
    "smede",
    "smelt",
    "smert",
    "smide",
    "smidt",
    "smigs",
    "smige",
    "smigt",
    "smigr",
    "smils",
    "smile",
    "smilt",
    "smink",
    "smisk",
    "smogs",
    "smovs",
    "smuds",
    "smugl",
    "smukt",
    "smuld",
    "smule",
    "smult",
    "smuts",
    "smyge",
    "smæde",
    "smægt",
    "smæks",
    "smæld",
    "smæsk",
    "smøgs",
    "smøge",
    "smøls",
    "smøle",
    "smørs",
    "smøre",
    "smurt",
    "småøs",
    "snack",
    "snadr",
    "snage",
    "snaks",
    "snaps",
    "snare",
    "snart",
    "snask",
    "snavs",
    "snave",
    "sneen",
    "sneet",
    "snegl",
    "snerp",
    "snert",
    "snese",
    "snifs",
    "snige",
    "snild",
    "snips",
    "snipe",
    "snits",
    "snoet",
    "snobs",
    "snogs",
    "snoge",
    "snold",
    "snooz",
    "snors",
    "snore",
    "snork",
    "snots",
    "snubl",
    "snude",
    "snuen",
    "snues",
    "snuet",
    "snuer",
    "snuse",
    "snust",
    "snusk",
    "snuts",
    "snyds",
    "snyde",
    "snydt",
    "snylt",
    "snære",
    "snært",
    "snævr",
    "snøft",
    "snøre",
    "snøvl",
    "snøvs",
    "soens",
    "søers",
    "soaps",
    "sober",
    "sobre",
    "soden",
    "sodas",
    "sodet",
    "soder",
    "sodes",
    "sofas",
    "sogns",
    "sogne",
    "soiré",
    "sojas",
    "solen",
    "soles",
    "soler",
    "solds",
    "solde",
    "solet",
    "solid",
    "solos",
    "solis",
    "solur",
    "solår",
    "somme",
    "somre",
    "sonar",
    "sonde",
    "sondr",
    "sonet",
    "soner",
    "sones",
    "sonor",
    "soppe",
    "sorgs",
    "sorts",
    "sorte",
    "sortn",
    "soten",
    "soter",
    "souls",
    "sound",
    "sovet",
    "sover",
    "soves",
    "sovse",
    "spaen",
    "spaer",
    "spade",
    "spage",
    "spagt",
    "spalt",
    "spams",
    "spand",
    "spang",
    "spank",
    "spant",
    "spars",
    "spare",
    "spark",
    "spats",
    "speak",
    "speed",
    "spege",
    "spejd",
    "spejl",
    "spelt",
    "sperm",
    "spice",
    "spids",
    "spigr",
    "spils",
    "spild",
    "spile",
    "spins",
    "spind",
    "spink",
    "spion",
    "spirs",
    "spire",
    "spise",
    "spist",
    "spjæt",
    "splat",
    "splid",
    "split",
    "spole",
    "spors",
    "spore",
    "sport",
    "spots",
    "spove",
    "spray",
    "spred",
    "sprit",
    "sprog",
    "sprud",
    "sprut",
    "spræk",
    "sprak",
    "spræl",
    "spræt",
    "sprød",
    "spule",
    "spuns",
    "spurt",
    "spurv",
    "spyet",
    "spyes",
    "spyer",
    "spyds",
    "spyts",
    "spæde",
    "spædt",
    "spæge",
    "spæks",
    "spænd",
    "spæne",
    "spærs",
    "spøgs",
    "spøge",
    "spøgt",
    "spøjs",
    "spørg",
    "spået",
    "spåns",
    "squaw",
    "stabs",
    "stabe",
    "stabl",
    "stads",
    "stade",
    "stafs",
    "stags",
    "stage",
    "staks",
    "stald",
    "stalk",
    "stamp",
    "stand",
    "stang",
    "stank",
    "stans",
    "stars",
    "start",
    "stase",
    "stats",
    "stavs",
    "stave",
    "stavn",
    "stavr",
    "steak",
    "steds",
    "stede",
    "stedt",
    "stegs",
    "stege",
    "stegt",
    "stejl",
    "stels",
    "stele",
    "stems",
    "stemt",
    "stens",
    "stene",
    "steps",
    "stien",
    "stier",
    "stift",
    "stige",
    "stiks",
    "stikl",
    "stils",
    "stile",
    "stilk",
    "still",
    "stiln",
    "stime",
    "stiml",
    "sting",
    "stink",
    "stipl",
    "stive",
    "stivn",
    "stjæl",
    "stjal",
    "stods",
    "stofs",
    "stoks",
    "stols",
    "stole",
    "stola",
    "stolp",
    "stolt",
    "stops",
    "store",
    "stort",
    "stork",
    "storm",
    "story",
    "stout",
    "stovt",
    "straf",
    "stram",
    "streg",
    "strib",
    "strid",
    "stred",
    "strik",
    "strip",
    "strit",
    "strop",
    "strut",
    "stryg",
    "strøg",
    "stræb",
    "stræk",
    "strøs",
    "strør",
    "strøm",
    "strås",
    "strål",
    "stubs",
    "studs",
    "stude",
    "stuen",
    "stues",
    "stuer",
    "stuks",
    "stumt",
    "stump",
    "stund",
    "stunt",
    "stups",
    "stuts",
    "stuve",
    "stygt",
    "styks",
    "style",
    "stylt",
    "styne",
    "styrs",
    "styre",
    "styrk",
    "styrt",
    "stæng",
    "stænk",
    "stærs",
    "stære",
    "stærk",
    "stæse",
    "stævn",
    "støbe",
    "støbt",
    "støds",
    "støde",
    "stødt",
    "støjs",
    "støje",
    "støns",
    "størs",
    "støre",
    "støvs",
    "støve",
    "støvl",
    "stået",
    "ståbi",
    "ståls",
    "suder",
    "sufis",
    "suget",
    "suger",
    "suges",
    "suite",
    "sujet",
    "sukat",
    "sukke",
    "sukre",
    "sulet",
    "sulen",
    "sules",
    "suler",
    "sulfo",
    "sulky",
    "sults",
    "sulte",
    "sumak",
    "summe",
    "sumps",
    "sumpe",
    "sunds",
    "sunde",
    "sundt",
    "super",
    "suppe",
    "suras",
    "surfe",
    "surre",
    "suset",
    "suser",
    "suste",
    "suses",
    "sushi",
    "sutte",
    "sutur",
    "svabr",
    "svada",
    "svage",
    "svagt",
    "svajs",
    "svaje",
    "svale",
    "svalt",
    "svamp",
    "svane",
    "svang",
    "svans",
    "svars",
    "svare",
    "svart",
    "sveds",
    "svede",
    "svedt",
    "svejf",
    "svejs",
    "svend",
    "svide",
    "svien",
    "svies",
    "svier",
    "svigs",
    "svige",
    "svigt",
    "svime",
    "sviml",
    "svins",
    "svind",
    "svine",
    "sving",
    "svips",
    "svirs",
    "svire",
    "svirp",
    "svits",
    "svovl",
    "svulm",
    "svups",
    "svælg",
    "sværs",
    "svære",
    "svært",
    "sværd",
    "sværg",
    "sværm",
    "svævs",
    "svæve",
    "svøbs",
    "svøbe",
    "svøbt",
    "swing",
    "swipe",
    "syede",
    "sydet",
    "syder",
    "sydes",
    "syden",
    "sydom",
    "sydpå",
    "sydud",
    "sygen",
    "syges",
    "syger",
    "sygne",
    "sylen",
    "syles",
    "sylds",
    "sylte",
    "synet",
    "syner",
    "synds",
    "synde",
    "synes",
    "synge",
    "synks",
    "synke",
    "synsk",
    "synål",
    "syren",
    "syres",
    "syrer",
    "syret",
    "syrne",
    "sysle",
    "sytøj",
    "syver",
    "syvti",
    "sæben",
    "sæbes",
    "sæber",
    "sæbet",
    "sæden",
    "sæder",
    "sædet",
    "sædes",
    "sække",
    "sælen",
    "sæler",
    "sælge",
    "solgt",
    "sænke",
    "særbo",
    "særks",
    "særke",
    "sæson",
    "sæter",
    "sætte",
    "søens",
    "søbad",
    "søbet",
    "søber",
    "søbes",
    "sødet",
    "søder",
    "sødes",
    "sødme",
    "søger",
    "søgte",
    "søges",
    "søjle",
    "søkos",
    "sølen",
    "sølet",
    "søles",
    "søler",
    "sølle",
    "sølvs",
    "sømme",
    "sømil",
    "søndr",
    "søren",
    "søret",
    "sørge",
    "sørme",
    "søsyg",
    "søsæt",
    "søsat",
    "søulk",
    "søvej",
    "søvns",
    "såede",
    "sådan",
    "sågar",
    "sålen",
    "såler",
    "såpas",
    "såret",
    "sårer",
    "såres",
    "såsom",
    "såsæd",
    "såten",
    "såter",
    "såvel",
    "tabet",
    "taber",
    "tabte",
    "tabes",
    "tabel",
    "tabus",
    "tackl",
    "tacos",
    "tafts",
    "taget",
    "tages",
    "tagne",
    "tagen",
    "tager",
    "tagge",
    "tajga",
    "taket",
    "takes",
    "takke",
    "takle",
    "taksi",
    "takst",
    "takts",
    "talar",
    "talen",
    "tales",
    "taler",
    "talte",
    "talgs",
    "talje",
    "talks",
    "talon",
    "tamme",
    "tamil",
    "tamps",
    "tampe",
    "tands",
    "tande",
    "tangs",
    "tanga",
    "tange",
    "tango",
    "tanks",
    "tanke",
    "tants",
    "tante",
    "tappe",
    "tapas",
    "tapen",
    "tapes",
    "tapet",
    "taper",
    "tapir",
    "tapre",
    "taras",
    "tarer",
    "tarif",
    "tarms",
    "tarme",
    "tarok",
    "tarot",
    "tarvs",
    "taske",
    "tasts",
    "taste",
    "tatar",
    "tater",
    "tauet",
    "tauer",
    "taven",
    "taves",
    "taver",
    "tavet",
    "tavls",
    "tavle",
    "tavse",
    "tavst",
    "taxas",
    "taxis",
    "taxie",
    "tazet",
    "teens",
    "teers",
    "teede",
    "teaks",
    "teams",
    "tegls",
    "tegns",
    "tegne",
    "teins",
    "teint",
    "teist",
    "tejne",
    "tejst",
    "tekop",
    "tekst",
    "telts",
    "telte",
    "temas",
    "tempo",
    "tempi",
    "tenen",
    "tenes",
    "tenor",
    "teori",
    "terms",
    "terme",
    "terns",
    "terne",
    "terpe",
    "terre",
    "terts",
    "tesen",
    "teses",
    "teser",
    "teske",
    "tests",
    "teste",
    "teten",
    "tetes",
    "thais",
    "theta",
    "thybo",
    "tiara",
    "tiden",
    "tider",
    "tiede",
    "tiers",
    "tiere",
    "tiger",
    "tigre",
    "tigge",
    "tikke",
    "tilde",
    "tilgå",
    "tilis",
    "tilse",
    "tilså",
    "tilte",
    "timen",
    "times",
    "timer",
    "timet",
    "timid",
    "tinde",
    "tindr",
    "tings",
    "tinge",
    "tinte",
    "tipis",
    "tippe",
    "tirre",
    "tiske",
    "tisse",
    "tital",
    "titan",
    "titel",
    "titte",
    "tiøre",
    "tiårs",
    "tjald",
    "tjalk",
    "tjans",
    "tjats",
    "tjavs",
    "tjeks",
    "tjene",
    "tjent",
    "tjept",
    "tjurs",
    "tjære",
    "tjørn",
    "toede",
    "toast",
    "tobak",
    "tobis",
    "toddy",
    "todel",
    "toers",
    "toere",
    "tofts",
    "tofte",
    "tofus",
    "toget",
    "toges",
    "togas",
    "to go",
    "togts",
    "tolds",
    "tolde",
    "tolks",
    "tolke",
    "tolvs",
    "tomme",
    "tomat",
    "tomle",
    "tomts",
    "tonal",
    "tonen",
    "tones",
    "toner",
    "tonet",
    "tonic",
    "tonse",
    "toppe",
    "topas",
    "topti",
    "toras",
    "tordn",
    "torns",
    "torne",
    "torps",
    "torsk",
    "torso",
    "torts",
    "torvs",
    "torve",
    "tosse",
    "total",
    "totem",
    "totis",
    "totne",
    "totte",
    "totur",
    "touch",
    "tough",
    "toupé",
    "touts",
    "tovet",
    "toves",
    "tover",
    "toårs",
    "tracé",
    "track",
    "tragt",
    "traké",
    "tramp",
    "trans",
    "trane",
    "trang",
    "trask",
    "travs",
    "trave",
    "travl",
    "trawl",
    "treer",
    "treks",
    "trema",
    "trend",
    "treti",
    "trevl",
    "trias",
    "trick",
    "trilr",
    "triml",
    "trins",
    "trind",
    "trine",
    "trios",
    "triol",
    "trips",
    "trist",
    "trits",
    "trivi",
    "triør",
    "troen",
    "troet",
    "trods",
    "trofæ",
    "trokl",
    "trokæ",
    "trold",
    "troml",
    "trone",
    "trops",
    "trope",
    "truck",
    "truet",
    "truer",
    "trues",
    "trugs",
    "trumf",
    "trups",
    "trust",
    "truts",
    "trygt",
    "trygl",
    "tryks",
    "trykt",
    "tryne",
    "træet",
    "træer",
    "træde",
    "trådt",
    "træfs",
    "træge",
    "trægt",
    "træks",
    "træls",
    "træns",
    "træne",
    "træng",
    "træsk",
    "trævl",
    "trøje",
    "trøst",
    "tråds",
    "tråde",
    "tsars",
    "tubas",
    "tuben",
    "tubes",
    "tuber",
    "tuden",
    "tudes",
    "tudet",
    "tuder",
    "tudse",
    "tuens",
    "tuers",
    "tuede",
    "tugts",
    "tugte",
    "tujas",
    "tukan",
    "tulle",
    "tulre",
    "tumbl",
    "tumle",
    "tumor",
    "tumpe",
    "tunen",
    "tunet",
    "tuner",
    "tunes",
    "tunge",
    "tungt",
    "turen",
    "tures",
    "turbo",
    "turde",
    "turet",
    "turer",
    "turné",
    "tusch",
    "tusks",
    "tuske",
    "tusse",
    "tutor",
    "tutte",
    "tutti",
    "tvang",
    "tvebo",
    "tvege",
    "tvind",
    "tving",
    "tvist",
    "tvivl",
    "tvære",
    "tvært",
    "tværs",
    "tweed",
    "tween",
    "tweet",
    "twill",
    "twist",
    "tyede",
    "tyden",
    "tydet",
    "tyder",
    "tydes",
    "tyfon",
    "tyfus",
    "tygge",
    "tykke",
    "tykne",
    "tylle",
    "tylvt",
    "tynde",
    "tyndt",
    "tynge",
    "typen",
    "types",
    "typer",
    "tyren",
    "tyres",
    "tyran",
    "tyret",
    "tyrer",
    "tyrks",
    "tysks",
    "tyske",
    "tysse",
    "tyste",
    "tystn",
    "tyven",
    "tyves",
    "tyver",
    "tyvte",
    "tæfts",
    "tæfte",
    "tægen",
    "tæges",
    "tæger",
    "tække",
    "tælle",
    "tæmme",
    "tænde",
    "tændt",
    "tænke",
    "tænkt",
    "tæppe",
    "tæret",
    "tærer",
    "tæres",
    "tærsk",
    "tærte",
    "tæsks",
    "tæske",
    "tætte",
    "tætne",
    "tæven",
    "tæves",
    "tæver",
    "tævet",
    "tøede",
    "tøffe",
    "tøfle",
    "tøjet",
    "tøjer",
    "tøjle",
    "tøjrs",
    "tøjre",
    "tøjte",
    "tølts",
    "tølte",
    "tømme",
    "tømte",
    "tømre",
    "tønde",
    "tørre",
    "tøris",
    "tørke",
    "tørns",
    "tørne",
    "tørst",
    "tørvs",
    "tøsen",
    "tøser",
    "tøses",
    "tøset",
    "tøsne",
    "tøvet",
    "tøver",
    "tøves",
    "tåens",
    "tæers",
    "tåben",
    "tåbes",
    "tåber",
    "tågen",
    "tåges",
    "tåger",
    "tåget",
    "tåler",
    "tålte",
    "tåles",
    "tåled",
    "tåren",
    "tåres",
    "tårer",
    "tårns",
    "tårne",
    "uanet",
    "ublid",
    "ubåds",
    "ubåde",
    "ud ad",
    "udarm",
    "udart",
    "udbed",
    "udbad",
    "udben",
    "udbor",
    "udbud",
    "udbul",
    "udbyd",
    "udbød",
    "udbyg",
    "udbyt",
    "udbær",
    "udbar",
    "uddan",
    "uddel",
    "uddyb",
    "uddyk",
    "uddør",
    "uddød",
    "udelt",
    "udfas",
    "udfor",
    "udfri",
    "udfør",
    "udgiv",
    "udgav",
    "udgyd",
    "udgød",
    "udgør",
    "udgår",
    "udgik",
    "udhug",
    "udhul",
    "udhus",
    "udhæv",
    "udjag",
    "udjog",
    "udkig",
    "udkik",
    "udkog",
    "udkom",
    "udkår",
    "udlad",
    "udlet",
    "udles",
    "udler",
    "udled",
    "udlej",
    "udlev",
    "udlod",
    "udlos",
    "udlov",
    "udlud",
    "udlug",
    "udlyd",
    "udlæg",
    "udlær",
    "udlæs",
    "udløb",
    "udløs",
    "udlån",
    "udmaj",
    "udmal",
    "udmat",
    "udmål",
    "udnyt",
    "udpeg",
    "udpib",
    "udpeb",
    "udpin",
    "udpos",
    "udred",
    "udret",
    "udriv",
    "udrev",
    "udrug",
    "udrul",
    "udryd",
    "udryk",
    "udrøj",
    "udrøm",
    "udrør",
    "udråb",
    "udsat",
    "udsav",
    "udset",
    "udses",
    "udsås",
    "udser",
    "udsig",
    "udson",
    "udspy",
    "udstå",
    "udsug",
    "udsyn",
    "udsyr",
    "udsæd",
    "udsæt",
    "udsøg",
    "udtag",
    "udtog",
    "udtal",
    "udton",
    "udtur",
    "udtyd",
    "udtær",
    "udtøm",
    "udtør",
    "udves",
    "udvej",
    "udvid",
    "udvis",
    "udyds",
    "udyrs",
    "udæsk",
    "udøbt",
    "udørk",
    "udøse",
    "udøst",
    "udøve",
    "udåds",
    "udånd",
    "uefne",
    "uegal",
    "uenig",
    "ufiks",
    "ufine",
    "ufint",
    "ufoen",
    "ufoet",
    "ufoer",
    "ufred",
    "ufrie",
    "ufrit",
    "ufødt",
    "uføre",
    "ugens",
    "ugers",
    "ugift",
    "uglen",
    "ugles",
    "ugler",
    "uglet",
    "ugræs",
    "uheld",
    "uhyre",
    "uhørt",
    "ujævn",
    "uklar",
    "uklog",
    "ukære",
    "ukært",
    "uland",
    "ulave",
    "ulden",
    "uldet",
    "ulder",
    "uldes",
    "uldne",
    "uligt",
    "ulige",
    "ulken",
    "ulkes",
    "ulmet",
    "ulmer",
    "ulmes",
    "ulven",
    "ulves",
    "ulyst",
    "ulærd",
    "ulæst",
    "uløst",
    "umage",
    "umami",
    "umbra",
    "umild",
    "under",
    "undte",
    "undes",
    "undgå",
    "undre",
    "undse",
    "undså",
    "yngre",
    "yngst",
    "ungen",
    "unges",
    "unger",
    "ungmø",
    "unikt",
    "unika",
    "union",
    "units",
    "unode",
    "unåde",
    "urens",
    "urers",
    "urets",
    "urene",
    "urans",
    "urban",
    "urdus",
    "urede",
    "uredt",
    "ureel",
    "urent",
    "urins",
    "urnen",
    "urnes",
    "urner",
    "uroen",
    "uroer",
    "uroet",
    "urrem",
    "urten",
    "urter",
    "urtid",
    "urund",
    "uryds",
    "uræmi",
    "urørt",
    "uråds",
    "usagt",
    "usand",
    "usete",
    "usket",
    "uskik",
    "uskøn",
    "ussel",
    "usund",
    "utaks",
    "utakt",
    "utals",
    "utalt",
    "utide",
    "uting",
    "utopi",
    "utryg",
    "utugt",
    "uturs",
    "utøjs",
    "uvane",
    "uvant",
    "uvejr",
    "uvens",
    "uvist",
    "uvorn",
    "uægte",
    "uøvet",
    "uåret",
    "vabel",
    "vable",
    "vadet",
    "vaden",
    "vades",
    "vader",
    "vager",
    "vagts",
    "vajds",
    "vajet",
    "vajer",
    "vajes",
    "vakre",
    "vakle",
    "vakse",
    "vakst",
    "valen",
    "valne",
    "valgs",
    "valid",
    "valks",
    "valke",
    "valle",
    "valms",
    "valme",
    "valse",
    "valsk",
    "valte",
    "valør",
    "vamle",
    "vamps",
    "vampe",
    "vamse",
    "vanen",
    "vaner",
    "vands",
    "vande",
    "vandr",
    "vanes",
    "vangs",
    "vange",
    "vanke",
    "vanry",
    "vants",
    "vante",
    "vanær",
    "varan",
    "varde",
    "varen",
    "vares",
    "varer",
    "varet",
    "varia",
    "varig",
    "varme",
    "varmt",
    "varps",
    "varpe",
    "varsl",
    "varte",
    "varvs",
    "varyl",
    "vasal",
    "vasen",
    "vases",
    "vaser",
    "vasks",
    "vaske",
    "vater",
    "vatre",
    "veens",
    "veers",
    "vedas",
    "vedet",
    "vedgå",
    "vegar",
    "vegne",
    "vejen",
    "vejes",
    "vejet",
    "vejer",
    "vejle",
    "vejrs",
    "vejre",
    "veksl",
    "velan",
    "velar",
    "velin",
    "velær",
    "vemod",
    "vende",
    "vendt",
    "venen",
    "venes",
    "vener",
    "vente",
    "venøs",
    "verfe",
    "verst",
    "verve",
    "vesir",
    "vests",
    "veste",
    "vetos",
    "vetoe",
    "viets",
    "viers",
    "vores",
    "viede",
    "viben",
    "vibes",
    "viber",
    "vidde",
    "vidst",
    "vides",
    "videt",
    "vider",
    "viden",
    "video",
    "vidje",
    "vidne",
    "views",
    "vifts",
    "vifte",
    "vigen",
    "viges",
    "veget",
    "viger",
    "vigte",
    "vigør",
    "vikar",
    "vikke",
    "vikle",
    "vikse",
    "vilde",
    "vildt",
    "vilje",
    "villa",
    "ville",
    "vimre",
    "vimst",
    "vimse",
    "vinen",
    "vines",
    "vinca",
    "vinds",
    "vinde",
    "vindt",
    "vandt",
    "vinge",
    "vinks",
    "vinke",
    "vinkl",
    "vinyl",
    "vinøs",
    "viols",
    "viola",
    "vippe",
    "virak",
    "viral",
    "viren",
    "vires",
    "virer",
    "viril",
    "virke",
    "virre",
    "virus",
    "viras",
    "visse",
    "visen",
    "vises",
    "viser",
    "viste",
    "visér",
    "visir",
    "visit",
    "visks",
    "viske",
    "visne",
    "visum",
    "visas",
    "vitas",
    "vital",
    "viven",
    "vlies",
    "vlogs",
    "vodka",
    "vogns",
    "vogne",
    "vogte",
    "voila",
    "vokal",
    "vokse",
    "volds",
    "volde",
    "voldt",
    "volfe",
    "volts",
    "volte",
    "volut",
    "vomme",
    "vomer",
    "vorde",
    "vorte",
    "voter",
    "votum",
    "votas",
    "voven",
    "voves",
    "vover",
    "vovet",
    "vovse",
    "vrags",
    "vrage",
    "vralt",
    "vrang",
    "vrede",
    "vredt",
    "vrids",
    "vride",
    "vriks",
    "vriml",
    "vrist",
    "vræls",
    "vræle",
    "vrælt",
    "vræng",
    "vrøvl",
    "vråen",
    "vråer",
    "vuets",
    "vuers",
    "vugge",
    "vulst",
    "vunde",
    "vupti",
    "vyets",
    "vyers",
    "væbne",
    "vædde",
    "væden",
    "vædes",
    "vædet",
    "væder",
    "vædre",
    "vægge",
    "vægen",
    "væges",
    "væger",
    "vægre",
    "vægts",
    "vægte",
    "vægur",
    "vække",
    "vakte",
    "vækst",
    "vælds",
    "vælde",
    "vælge",
    "valgt",
    "vælig",
    "vælsk",
    "vælte",
    "vænge",
    "vænne",
    "værds",
    "værdi",
    "været",
    "væres",
    "væren",
    "værft",
    "værge",
    "værks",
    "værke",
    "værns",
    "værne",
    "værre",
    "værst",
    "værts",
    "væsel",
    "væsen",
    "væske",
    "vætte",
    "væven",
    "væves",
    "vævet",
    "væver",
    "vævre",
    "vølve",
    "våben",
    "våden",
    "vådes",
    "våder",
    "vådte",
    "vågen",
    "våges",
    "våger",
    "våget",
    "vågne",
    "vånds",
    "vånde",
    "våren",
    "våret",
    "vårer",
    "våres",
    "våset",
    "våser",
    "våses",
    "wadis",
    "wagon",
    "watts",
    "wengé",
    "whist",
    "wikis",
    "wings",
    "wiren",
    "wires",
    "wirer",
    "wraps",
    "xenon",
    "yacht",
    "yangs",
    "yards",
    "yatzy",
    "ydede",
    "yders",
    "ydere",
    "ydmyg",
    "ydret",
    "ydres",
    "ydrer",
    "ymers",
    "ymtet",
    "ymter",
    "ymtes",
    "ynden",
    "yndes",
    "ynder",
    "yndet",
    "yndig",
    "yngel",
    "yngle",
    "ynket",
    "ynker",
    "ynkes",
    "yogas",
    "yogis",
    "yoyos",
    "yppet",
    "ypper",
    "yppes",
    "yppig",
    "ytret",
    "ytrer",
    "ytres",
    "yuans",
    "yucca",
    "yvers",
    "yvere",
    "zappe",
    "zaren",
    "zarer",
    "zebra",
    "zebus",
    "zefyr",
    "zelot",
    "zenit",
    "zetas",
    "zinks",
    "zloty",
    "zobel",
    "zonen",
    "zones",
    "zoner",
    "zooen",
    "zooer",
    "zooms",
    "zoome",
    "zulus",
    "zweck",
    "æblet",
    "æbles",
    "æbler",
    "ædens",
    "ædets",
    "ædelt",
    "æders",
    "ædere",
    "æggen",
    "ægges",
    "ægget",
    "ægger",
    "ægide",
    "ægtet",
    "ægter",
    "ægtes",
    "ækelt",
    "ækles",
    "ælden",
    "ældes",
    "ældet",
    "ælder",
    "ælten",
    "æltet",
    "æltes",
    "ælter",
    "ændre",
    "ængst",
    "ænset",
    "ænser",
    "ænses",
    "æonen",
    "æoner",
    "ærens",
    "ærene",
    "ærers",
    "æraen",
    "æraer",
    "ærbar",
    "ærede",
    "ærgre",
    "ærlig",
    "ærmet",
    "ærmes",
    "ærmer",
    "ærten",
    "ærter",
    "ærøbo",
    "ærøsk",
    "æslet",
    "æsels",
    "æsler",
    "æsken",
    "æskes",
    "æsker",
    "æstet",
    "ætten",
    "ætter",
    "ætans",
    "æters",
    "ætere",
    "ætset",
    "ætser",
    "ætses",
    "ætyls",
    "ævlet",
    "ævler",
    "ævles",
    "ævred",
    "øerne",
    "øboen",
    "øboer",
    "ødets",
    "øders",
    "ødede",
    "ødtes",
    "ødems",
    "ødsel",
    "ødsle",
    "øffet",
    "øffer",
    "øffes",
    "øgets",
    "øgene",
    "øgede",
    "øglen",
    "øgles",
    "øgler",
    "øhavs",
    "øhave",
    "øhops",
    "øjets",
    "øjers",
    "øjnes",
    "øjede",
    "øjnet",
    "øjner",
    "øksen",
    "økses",
    "økser",
    "økset",
    "øllet",
    "øllen",
    "øller",
    "ølbas",
    "ølvom",
    "ømhed",
    "ømmet",
    "ømmer",
    "ømmes",
    "ønske",
    "ørens",
    "ørene",
    "ørers",
    "ørets",
    "ørige",
    "ørken",
    "ørnen",
    "ørnes",
    "ørred",
    "øsens",
    "øsers",
    "øsede",
    "østes",
    "øsken",
    "østat",
    "østen",
    "østom",
    "østpå",
    "østre",
    "østud",
    "øvdag",
    "øvede",
    "øvrig",
    "åerne",
    "åbent",
    "åbnet",
    "åbner",
    "åbnes",
    "ådret",
    "ådrer",
    "ådres",
    "ådsel",
    "ågets",
    "ågene",
    "ågers",
    "ågret",
    "ågrer",
    "ågres",
    "ålens",
    "ålene",
    "ålede",
    "ånden",
    "ånder",
    "åndes",
    "åndet",
    "åndig",
    "årets",
    "årene",
    "årbog",
    "årens",
    "årers",
    "årede",
    "åring",
    "årlig",
    "årsag",
    "årtis",
    "åsens",
    "åsene",
    "åsted",
    "åsyns",
].map(x => x.toUpperCase());
