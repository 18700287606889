import React from 'react';
import { WORDS } from './words';
import './app.scss';
import '@fortawesome/fontawesome-free/css/all.css';

const height: number = 6;
const width: number = 5;

enum SquareColor {
  None = "none",
  Invalid = "invalid",
  WrongPlacement = "wrong-placement",
  Correct = "correct"
}

function getEmptySquare() {
  const EmptySquare: Square = {
    letter: "",
    color: SquareColor.None
  }

  return { ...EmptySquare }
};

interface Square {
  letter: string;
  color: SquareColor;
}

const classNames = (...classes: Array<string>) => classes.join(' ');
const cloneBoard = (board: Array<Array<Square>>) => JSON.parse(JSON.stringify(board));
const randomWord = () => WORDS[Math.round(Math.random() * WORDS.length)];
const defaultBoard = cloneBoard([...Array(height).keys()].map(_ => [...Array(width).keys()].map(_ => getEmptySquare())));

function App() {
  const [gameBoard, setGameBoard] = React.useState<Array<Array<Square>>>(cloneBoard(defaultBoard));
  const [x, setX] = React.useState<number>(0);
  const [y, setY] = React.useState<number>(0);
  const [word, setWord] = React.useState<Array<string>>([]);
  const [gameOver, setGameOver] = React.useState<boolean>(false);
  const [correctLetters, setCorrectLetters] = React.useState<Array<string>>([]);
  const [invalidLetters, setinvalidLetters] = React.useState<Array<string>>([]);
  const [wrongPlacementLetters, setWrongPlacementLetters] = React.useState<Array<string>>([]);
  const [outputText, setOutputText] = React.useState<string>("Velkommen");
  const [outputColor, setOutputColor] = React.useState<string>(SquareColor.None);
  const [hintLength, setHintLength] = React.useState<number>(0);
  const [colorSchemeSwapped, setColorSchemeSwapped] = React.useState<boolean>(false);

  const viewElement = document.getElementById("view");

  const reset = React.useCallback(() => {
    if (word.length > 0 && !gameOver) {
      if (!window.confirm("Start nyt spil?")) {
        return;
      }
    }

    setX(0);
    setY(0);
    setWord([...randomWord()]);
    setGameBoard(cloneBoard(defaultBoard));
    setGameOver(false);
    setinvalidLetters([]);
    setCorrectLetters([]);
    setWrongPlacementLetters([]);
    setOutputText("");
    setOutputColor(SquareColor.None);
    setHintLength(0);
    viewElement?.scrollTo(0, 0);
  }, [word, gameOver, viewElement]);

  React.useEffect(() => {
    const newGameBoardString = window.localStorage.getItem("gameBoard");
    const newXString = window.localStorage.getItem("x");
    const newYString = window.localStorage.getItem("y");
    const newWordString = window.localStorage.getItem("word");
    const newGameOverString = window.localStorage.getItem("gameOver");
    const newCorrectLettersString = window.localStorage.getItem("correctLetters");
    const newInvalidLettersString = window.localStorage.getItem("invalidLetters");
    const newWrongPlacementLettersString = window.localStorage.getItem("wrongPlacementLetters");
    const newOutputColorString = window.localStorage.getItem("outputColor");
    const newHintLengthString = window.localStorage.getItem("hintLength");
    const newDarkmodeString = window.localStorage.getItem("darkmode");

    if (word.length === 0) {
      if (newGameBoardString
        && newXString
        && newYString
        && newWordString
        && newGameOverString
        && newCorrectLettersString
        && newInvalidLettersString
        && newWrongPlacementLettersString
        && newOutputColorString
        && newHintLengthString
        && newDarkmodeString) {
        const newGameBoard = JSON.parse(newGameBoardString);
        const newX = JSON.parse(newXString);
        const newY = JSON.parse(newYString);
        const newWord = JSON.parse(newWordString);
        const newGameOver = JSON.parse(newGameOverString);
        const newCorrectLetters = JSON.parse(newCorrectLettersString);
        const newInvalidLetters = JSON.parse(newInvalidLettersString);
        const newWrongPlacementLetters = JSON.parse(newWrongPlacementLettersString);
        const newOutputColor = JSON.parse(newOutputColorString);
        const newHintLength = JSON.parse(newHintLengthString);
        const newDarkmode = JSON.parse(newDarkmodeString);

        if (typeof (newGameBoard) === "object" && newGameBoard.constructor === Array
          && typeof (newX) === "number"
          && typeof (newY) === "number"
          && typeof (newWord) === "object" && newWord.constructor === Array
          && typeof (newGameOver) === "boolean"
          && typeof (newCorrectLetters) === "object" && newCorrectLetters.constructor === Array
          && typeof (newInvalidLetters) === "object" && newInvalidLetters.constructor === Array
          && typeof (newWrongPlacementLetters) === "object" && newWrongPlacementLetters.constructor === Array
          && typeof (newOutputColor) === "string"
          && typeof (newHintLength) === "number"
          && typeof (newDarkmode) === "boolean") {
          setGameBoard(newGameBoard);
          setX(newX);
          setY(newY);
          setWord(newWord);
          setGameOver(newGameOver);
          setCorrectLetters(newCorrectLetters);
          setinvalidLetters(newInvalidLetters);
          setWrongPlacementLetters(newWrongPlacementLetters);
          setOutputColor(newOutputColor);
          setHintLength(newHintLength);
          setColorSchemeSwapped(newDarkmode);
        }
        else {
          reset();
        }
      }
      else {
        reset();
      }
    }
  }, [reset, word]);

  React.useEffect(() => {
    window.localStorage.setItem("gameBoard", JSON.stringify(gameBoard));
  }, [gameBoard]);
  React.useEffect(() => {
    window.localStorage.setItem("x", JSON.stringify(x));
  }, [x]);
  React.useEffect(() => {
    window.localStorage.setItem("y", JSON.stringify(y));
  }, [y]);
  React.useEffect(() => {
    window.localStorage.setItem("word", JSON.stringify(word));
  }, [word]);
  React.useEffect(() => {
    window.localStorage.setItem("gameOver", JSON.stringify(gameOver));
  }, [gameOver]);
  React.useEffect(() => {
    window.localStorage.setItem("correctLetters", JSON.stringify(correctLetters));
  }, [correctLetters]);
  React.useEffect(() => {
    window.localStorage.setItem("invalidLetters", JSON.stringify(invalidLetters));
  }, [invalidLetters]);
  React.useEffect(() => {
    window.localStorage.setItem("wrongPlacementLetters", JSON.stringify(wrongPlacementLetters));
  }, [wrongPlacementLetters]);
  React.useEffect(() => {
    window.localStorage.setItem("outputColor", JSON.stringify(outputColor));
  }, [outputColor]);
  React.useEffect(() => {
    window.localStorage.setItem("hintLength", JSON.stringify(hintLength));
  }, [hintLength]);
  React.useEffect(() => {
    window.localStorage.setItem("darkmode", JSON.stringify(colorSchemeSwapped));
  }, [colorSchemeSwapped]);

  function input(letter: string) {
    if (x === gameBoard[0].length) {
      return;
    }

    const newBoard = cloneBoard(gameBoard);

    newBoard[y][x].letter = letter;

    setX(x + 1);
    setGameBoard(newBoard);
    setOutputText("");
    setOutputColor(SquareColor.None);
  }

  function del() {
    if (x === 0) {
      return;
    }

    const newBoard = cloneBoard(gameBoard);

    newBoard[y][x - 1].letter = "";

    setX(x - 1);
    setGameBoard(newBoard);
    setOutputText("");
    setOutputColor(SquareColor.None);
  }

  function guess() {
    if (gameOver) {
      return;
    }

    if (x < width) {
      return;
    }

    const guessedWord: string = (gameBoard[y].map(x => x.letter)).join("");

    if (WORDS.indexOf(guessedWord) < 0) {
      setOutputText("Ukendt ord: " + guessedWord);
      setOutputColor(SquareColor.Invalid);
      return;
    }

    const newBoard = cloneBoard(gameBoard);
    const newCorrectLetters = [...correctLetters];
    const newInvalidLetters = [...invalidLetters];
    const newWrongPlacementLetters = [...wrongPlacementLetters];

    for (let i = 0; i < width; ++i) {
      newBoard[y][i].color = SquareColor.Invalid;
    }

    let match = [false, false, false, false, false];
    let wrongPositions = [false, false, false, false, false];

    for (let i = 0; i < width; ++i) {
      const letter = newBoard[y][i].letter;
      const lookAt = word[i];

      if (letter === lookAt) {
        match[i] = true;
        newBoard[y][i].color = SquareColor.Correct;

        if (newCorrectLetters.indexOf(lookAt) < 0) {
          newCorrectLetters.push(lookAt);
        }
      }
    }

    var allFound = match.reduce((previousValue, currentValue) => previousValue && currentValue, true);

    let isGameOver = false;

    if (y === height - 1) {
      if (!allFound) {
        setOutputText("Ordet var: " + word.join(""));
        setOutputColor(SquareColor.WrongPlacement);
      }

      isGameOver = true;
    }

    if (allFound) {
      isGameOver = true;

      setOutputText("Ordet er: " + word.join(""));
      setOutputColor(SquareColor.Correct);
    }

    if (!isGameOver) {
      for (let i = 0; i < width; ++i) {
        if (!match[i]) {
          for (let test = 0; test < width; ++test) {
            if (!match[test] && !wrongPositions[test]) {
              const letter = newBoard[y][i].letter;
              const lookAt = word[test];

              if (letter === lookAt) {
                newBoard[y][i].color = SquareColor.WrongPlacement;
                wrongPositions[test] = true;

                if (newWrongPlacementLetters.indexOf(lookAt) < 0) {
                  newWrongPlacementLetters.push(lookAt);
                }

                break;
              }
            }
          }
        }
      }
    }

    for (let i = 0; i < width; ++i) {
      const { color, letter } = newBoard[y][i];

      if (color === SquareColor.Invalid) {
        if (newInvalidLetters.indexOf(letter) < 0) {
          newInvalidLetters.push(letter);
        }
      }
    }

    if (y >= (height / 2)) {
      viewElement?.scrollTo(0, viewElement.clientHeight);
    }

    setGameOver(isGameOver);
    setX(0);
    setY(y + 1);
    setGameBoard(newBoard);
    setCorrectLetters(newCorrectLetters);
    setinvalidLetters(newInvalidLetters);
    setWrongPlacementLetters(newWrongPlacementLetters);

    if (!isGameOver) {
      setOutputText("");
      setOutputColor(SquareColor.None);
    }
  }

  function getColor(letter: string) {
    if (correctLetters.indexOf(letter) >= 0) {
      return SquareColor.Correct;
    }
    else if (wrongPlacementLetters.indexOf(letter) >= 0) {
      return SquareColor.WrongPlacement;
    }
    else if (invalidLetters.indexOf(letter) >= 0) {
      return SquareColor.Invalid;
    }

    return SquareColor.None;
  }

  function keyPress(event: KeyboardEvent) {
    const key = event.key.toUpperCase();

    if (key.length === 1 && "QWERTYUIOPÅASDFGHJKLÆØZXCVBNM".indexOf(key) >= 0) {
      input(key);
    }
  }

  function hint() {
    if (gameOver) {
      return;
    }

    let newHintLength = hintLength;

    if (hintLength < word.length) {
      if (window.confirm("Ønsker du et hint?")) {
        ++newHintLength;
      }
      else {
        return;
      }
    }

    const reveal = "Ordet er: "
      + word.slice(0, newHintLength).join("")
      + new Array(word.length - newHintLength + 1).join("*");

    setOutputText(reveal);
    setOutputColor(SquareColor.WrongPlacement);
    setHintLength(newHintLength);
  }

  //register keyboard events
  window.onkeyup = keyPress;

  const getInputButtons = (letters: string) =>
    [...letters].map((letter, index) => <InputButton
      letter={letter}
      key={"btnLetter" + index}
      colorClass={getColor(letter)}
      onClick={letter => input(letter)}
      disabled={gameOver} />);

  return (
    <div className={classNames("game", colorSchemeSwapped ? "color-scheme-initial" : "color-scheme-swapped")}>
      <div className={classNames("output", outputColor)}>
        <div className="output-text">
          <div className="output-text-inner">
            {outputText}
          </div>
        </div>
      </div>
      <div className="view" id="view">
        {gameBoard.map((row, rowIndex) => {
          return (
            <React.Fragment key={"viewOutput" + rowIndex}>
              <div className="square none"><div className="square-inner">{rowIndex + 1}</div></div>
              {row.map((square, squareIndex) => {
                return (
                  <div className={classNames("square", square.color.toString())} key={"square_" + rowIndex + "_" + squareIndex} >
                    <div className="square-inner">
                      {square.letter}
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
      <div className="input">
        {getInputButtons("QWERTYUIOP")}
        {getInputButtons("ASDFGHJKLÅ")}
        {getInputButtons("ZXCVBNMÆØ")}
        <button
          onClick={() => setColorSchemeSwapped(!colorSchemeSwapped)}
          className={classNames("btn", "none")}
          style={{ gridArea: "DM" }}>
          <i className="fas fa-adjust" />
        </button>
        <button
          onClick={() => del()}
          className={classNames("btn", "none")}
          style={{ gridArea: "DEL" }}
          disabled={x === 0}>
          <i className="fas fa-step-backward" />
        </button>
        <button
          onClick={() => reset()}
          className={classNames("btn", "none")}
          style={{ gridArea: "NEW" }}>
          Nyt ord
        </button>
        <button
          onClick={() => hint()}
          className={classNames("btn", "none")}
          disabled={gameOver}
          style={{ gridArea: "HINT" }}>
          Hint
          <br />
          <span className="btn-small-text">({hintLength} af 5 brugt)</span>
        </button>
        <button
          onClick={() => guess()}
          className={classNames("btn", "none")}
          style={{ gridArea: "GUESS" }}
          disabled={x < width}>
          Gæt
        </button>
        <div className="copyright">
          <div className="copyright-text">
            Oc6.dk Ordleg. Copyright 2022 - Marc Barnholdt
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

interface IInputButtonProps {
  disabled: boolean;
  letter: string;
  colorClass: string;
  onClick: (letter: string) => void;
}

const InputButton = (props: IInputButtonProps) =>
  <button
    disabled={props.disabled}
    style={{ gridArea: props.letter }}
    className={classNames("btn", props.colorClass)}
    onClick={() => props.onClick(props.letter)}>
    {props.letter}
  </button>;